<app-header></app-header>
<div class="container container-lg" id="Trips">

    <div class="row">
        <div class="col-auto" id="leftSidebar">
            <div class="p-0" *ngIf="userDetails.live_user_type!=2">
                <div style="padding:8px;padding-top:0px;margin: 0;">
                    <div class="search card-shadow">
                        <div class="row search-box">
                            <div class="col-4 paddingDate">
                                <p class="dateStyle" (click)="picker.open()">
                                    {{searchDate | date: 'MMM dd'}}
                                    <img class="dividerStyle" src="assets/images/divider_black.svg" alt="divider">
                                </p>
                                <input matInput hidden [matDatepicker]="picker" [value]="todayDate"
                                    (dateChange)="dateChange($event)">
                                <mat-datepicker #picker></mat-datepicker>
                            </div>

                            <div class="col-8" style="margin-left: -7%">
                                <input type="search" id="search" name="search" placeholder="Type to Search"
                                    class="form-control" [(ngModel)]="searchString"
                                    (change)="searchL()" autocomplete="off" />
                            </div>
                        </div>
                        <hr>
                        <div class="row py-2 tab-bar">
                            <div class="col pr-2" [class.active]="selectedIndex == 0" (click)="setoffset(0);">Completed
                            </div>
                            <div class="col" [class.active]="selectedIndex == 1" (click)="setoffset(1); ">Scheduled
                            </div>
                            <div class="col pl-2 mr-1" [class.active]="selectedIndex == 2" (click)="setoffset(2);">
                                On-Demand
                            </div>
                        </div>
                        <div *ngIf="selectedIndex == 0" class="row subTab">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(true, false, false, false, false)"
                                    class="leftSubTab" [class.active]="isScheduledFilter">Scheduled </p>
                            </div>
                            <img class="dividerStyle" src="assets/images/divider_black.svg" alt="divider">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(false, true, false, false, false)"
                                    [class.active]="isRegularFilter">On-Demand</p>
                            </div>
                        </div>
                        <div *ngIf="selectedIndex == 1" class="row subTab">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(false, false, false, false, true)"
                                    class="leftSubTab" [class.active]="isCancelledFilter">Cancelled </p>
                            </div>
                            <img class="dividerStyle" src="assets/images/divider_black.svg" alt="divider">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(false, false, true, false, false)"
                                    [class.active]="isUpcomingFilter">Upcoming</p>
                            </div>
                        </div>
                        <div *ngIf="selectedIndex == 2" class="row subTab">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(false, false, false, false, true)"
                                    class="leftSubTab" [class.active]="isCancelledFilter">Cancelled </p>
                            </div>
                            <img class="dividerStyle" src="assets/images/divider_black.svg" alt="divider">
                            <div class="col ">
                                <p style="cursor: pointer;" (click)="setSubFilters(false, false, false, true, false)"
                                    [class.active]="isOngoingFilter">Ongoing</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="trip_detail_data" style="padding:0px 8px 8px" [ngClass]="{'whirl' : isSubLoading }">
                    <ng-container *ngIf="isTripListLoading">
                        <div *ngFor="let i of skeltonCount" class="card-shadow tripCardHeight item">
                            <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedIndex === 0 && !isTripListLoading">
                        <div class="card-shadow tripCardHeight" *ngFor="let data of completedrides; let i=index"
                            (mousedown)="isTripDetailLoading=true;resetRetryRide();expandDisc(data,i, true);"
                            [class.active_trip_data]="rideInFocusIndex == i && activeRideType === activeRideEnum[0]">
                            <div class="user-name">
                                {{cropName(data.user_name, 15)}}
                            </div>
                            <div class="time" *ngIf="data.drop_time">
                                {{data.drop_time|date:'MM/dd/yyyy'}} | {{data.drop_time|date:'HH:mm a'}} -
                                ({{data.offset | timezone}})
                            </div>
                            <!-- <div class="time" *ngIf="data.drop_time">
                                {{data.start_time|date:'MM/dd/yyyy'}} | {{data.start_time|date:'HH:mm a'}} -
                                ({{data.offset | timezone}})
                            </div> -->
                            <div class="status-label">
                                Completed
                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2 ml-1">Assigned</span>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedIndex === 1 && !isTripListLoading">
                        <div class="card-shadow tripCardHeight" *ngFor="let data of scheduledrides; let i=index"
                            (mousedown)="isTripDetailLoading=true;resetRetryRide();expandDisc(data,i, true);"
                            [class.active_trip_data]="rideInFocusIndex == i && activeRideType === activeRideEnum[1]">
                            <div class="user-name">
                                {{cropName(data.user_name, 15)}}
                            </div>
                            <div class="time" *ngIf="data.pickup_time">
                                {{data.pickup_time|date:'MM/dd/yyyy'}} | {{data.pick_up_time | uppercase}} -
                                ({{data.offset |
                                timezone}})
                            </div>
                            <div class="time" *ngIf="!data.pickup_time">
                                {{data.date|date:'MM/dd/yyyy'}} | {{data.pick_up_time | uppercase}} -
                                ({{data.offset |
                                timezone}})
                            </div>
                            <div class="status-label status-color"
                                [ngClass]="getButtonClass(data.ride_status,data.request_status,data.is_active)">
                                <span
                                    class="cursor-pointer">{{getButtonText(data.ride_status,data.request_status,data.is_active)}}</span>
                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2 ml-1">Assigned</span>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedIndex === 2 && !isTripListLoading">
                        <div class="card-shadow tripCardHeight" *ngFor="let data of requestedrides; let i=index"
                            (mousedown)="isTripDetailLoading=true;resetRetryRide();expandDisc(data,i, true);"
                            [class.active_trip_data]="rideInFocusIndex == i && activeRideType === activeRideEnum[2]">
                            <div class="user-name">
                                {{cropName(data.user_name, 15)}}
                            </div>
                            <div class="time">
                                {{data.date|date:'MM/dd/yyyy'}} | {{data.date|date:'HH:mm a'}} - ({{data.offset |
                                timezone}})
                            </div>
                            <div class="status-label status-color"
                                [ngClass]="getButtonClass(data.ride_status,data.request_status,data.is_active)">
                                <span
                                    class="cursor-pointer">{{getButtonText(data.ride_status,data.request_status,data.is_active)}}
                                </span>
                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2 ml-1">Assigned</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div style="padding: 0px 8px;">
                    <div id="page-navigation-bar" class="card-shadow">
                        <hr>
                        <div align="center" class="page-center" style="margin: 3px;">
                            <mat-paginator [length]="totalItemsScheduled" [showFirstLastButtons]="true"
                                [hidePageSize]="true" [pageSize]="query.limit" (page)="paginate($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
                <!-- Left Side for Drivers -->
                <!-- LEft Side for drivers Ends -->
            </div>

        </div>
        <div class="col">
            <div id="expandedDiv" class="middle-bar card-shadow  active-background">
                <div class="tab-panel"
                    style="position: -webkit-sticky; position: sticky; top: 0px;background-color: white;z-index: 2;">
                    <div class="row" style="border-radius: 5px 5px 0px 0px">
                        <div *ngIf="!retryRide" class="col-2 pl-4">
                            <div class="cursor-pointer" style="white-space: nowrap;"
                                [class.active-tab]="active_trip_tab == 0" (click)="active_trip_tab = 0">Details
                            </div>
                        </div>
                        <div *ngIf="!retryRide" class="col-2 pl-3 statusTabPadding">
                            <div class="cursor-pointer" style="white-space: nowrap;"
                                [class.active-tab]="active_trip_tab == 1" (click)="active_trip_tab = 1">Status
                            </div>
                        </div>
                        <div *ngIf="!retryRide" class="col-4 pl-1 timelineTabPadding">
                            <div class="cursor-pointer" style="white-space: nowrap;"
                                [class.active-tab]="active_trip_tab == 2" (click)="active_trip_tab = 2">Timeline
                            </div>
                        </div>
                        <div *ngIf="retryRide" class="col-8">
                            <div class="cursor-pointer" style="white-space: nowrap;" [class.active-tab]="true">Return
                                Ride Request
                            </div>
                        </div>
                        <div *ngIf="!isTripDetailLoading" class="col-auto tripNumberStyle">
                            <img src="assets/images/divider_black.svg" alt="divider">
                            &nbsp;{{tripnumber}}
                        </div>
                    </div>
                </div>

                <div class="table td-section" style="border-radius: 5px 5px 0px 0px">
                    <div class="tdrs">
                        <ng-container *ngIf="isTripDetailLoading">
                            <div>
                                <div class="mb-3">
                                    <div class="row col-md-12 mt-4 mb-1 px-2">
                                        <div class="col-8 mr-auto">
                                            <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                        </div>
                                        <div class="col-auto">
                                            <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 mt-2 mb-1 px-2">
                                        <div class="col-8 mr-auto">
                                            <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                        </div>
                                        <div class="col-4">
                                        </div>
                                    </div>

                                    <div class="col-auto mt-2 mb-1 px-4">
                                        <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                    </div>

                                    <div class="col-auto mt-2 mb-4 px-4">
                                        <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                    </div>
                                </div>

                                <hr>

                                <div class="my-3">
                                    <div class="row col-md-12 mt-2 mb-2 mb-1 px-2">
                                        <div class="col-8 mr-auto">
                                            <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                        </div>
                                        <div class="col-auto">
                                            <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 mt-2 mb-2 mb-1 px-2">
                                        <div class="col-8 mr-auto">
                                            <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                        </div>
                                        <div class="col-4">
                                        </div>
                                    </div>

                                    <div class="col-auto mt-2 px-4">
                                        <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isTripDetailLoading">
                            <div *ngIf="active_trip_tab == 0 && !retryRide">
                                <div class="mb-3">
                                    <div class="row col-md-12 mt-4 mb-1 px-2">
                                        <div class="col-auto mr-auto">
                                            <label class="l1">Passenger</label><br>
                                            <label class="l2">{{cropName(user_name, 25)}}<br>{{user_mobile}}</label>
                                        </div>
                                        <div class="col-auto">
                                            <img src="{{user_image}}" *ngIf="user_image!=''" style="border-radius:50%;"
                                                class="center-block image" />
                                            <img src="http://qudos-s3.s3.amazonaws.com/user_profile/user.png"
                                                *ngIf="user_image==''" style="border-radius:50%; "
                                                class="center-block image dummy" />
                                        </div>
                                    </div>

                                    <div *ngIf="booked_by_corporate" class="col-auto mb-1 px-4">
                                        <label class="l1">Requested by Corporate</label><br>
                                        <label class="l2">{{corporate_name}}<br>{{corporate_mobile}}</label>
                                    </div>

                                    <div class="col-auto mb-1 px-4">
                                        <label class="l1">Ride Type</label><br>
                                        <label class="l2">{{car_name}}</label>
                                        <label *ngIf="ride_essential == 1"
                                            style="font-size: 14px; font-weight: 600;color: #28a745"> -
                                            Essentials</label>
                                    </div>

                                    <div class="col-auto mb-1 px-4">
                                        <label class="l1">Status</label><br>
                                        <span class="hidden hide">|| Missed ||</span>
                                        <label class="l2"
                                            style="background-color: #f9f9f9;font-size:12px;font-weight:550;color: #000">
                                            <span
                                                *ngIf="(selectedIndex=='1' || selectedIndex=='2' ) &&( ride_status == '4' || request_status == '4')">Completed</span>
                                            <span
                                                *ngIf="selectedIndex=='0' && ride_status == '4' || request_status == '4'">Completed
                                                <br><span>${{total_with_tax}}</span></span>
                                            <span *ngIf="(selectedIndex=='1' && request_status=='0')">Assigning</span>
                                            <span
                                                *ngIf="((selectedIndex=='1') &&((request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' ) ))">Missed</span>
                                            <span
                                                *ngIf="selectedIndex=='1' && request_status=='1' && ride_status=='0' && is_active=='1'">Accepted<br><span>Total
                                                    Payment: ${{total_with_tax}}</span></span>
                                            <span *ngIf="ride_status=='1'">Estimate To
                                                Pickup <br><span>{{estimate_pickup_time | number:0}} mins
                                                </span></span>
                                            <span *ngIf="ride_status=='2'">Arrived</span>
                                            <span *ngIf="ride_status=='3'">Estimate To
                                                Arrival <br><span>
                                                    {{estimate_diff>=0 ?(timeConvert(estimate_diff)):'0'}}
                                                </span> </span>
                                            <span *ngIf="ride_status=='6' || ride_status=='7'">Cancelled by
                                                Rider</span>
                                            <span *ngIf="ride_status=='5'">Cancelled by
                                                Driver <br><span>Driver:{{cropName(driver_name, 25)}}</span></span>
                                            <span *ngIf="ride_status=='11'">Cancelled by
                                                Corporate</span>
                                            <span *ngIf="ride_status=='9'">Cancelled by
                                                Admin</span>
                                            <span *ngIf="ride_status=='8' || request_status=='8'">Unsuccessful</span>
                                            <span
                                                *ngIf="selectedIndex=='2'  && ride_status=='0' && is_active == '1'">Driver
                                                Time To accept <br><span>{{timer}} mins </span></span>
                                            <span
                                                *ngIf="selectedIndex=='2' && ride_status=='0' && is_active == '0'">Missed</span>
                                        </label>
                                    </div>

                                    <div class="col-auto mb-1 px-4">
                                        <label class="l1">Requested Pickup Location</label><br>
                                        <label class="l2">{{pickup_location_address}}
                                        </label>
                                    </div>

                                    <div class="col-auto mb-1 px-4">
                                        <label class="l1">Dropoff Location</label><br>
                                        <label class="l2">{{drop_address}}
                                        </label>
                                    </div>

                                    <div class="col-auto mb-1 px-4">
                                        <label class="l1">Requested Dropoff Location</label><br>
                                        <label class="l2">{{manual_destination_address}}</label>
                                    </div>
                                </div>
                                <hr>
                                <div class="my-3">
                                    <div class="row col-md-12 mt-2 mb-2 mb-1 px-2">
                                        <div class="col-auto mr-auto">
                                            <label class="user_type l1"> Driver</label><br>
                                            <label class="user_name l2"
                                                *ngIf="(driver_name && !(request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status== null && ride_status == '0' ) )">
                                                {{cropName(driver_name, 25)}}<br>{{driver_mobile}}
                                            </label>
                                            <label class="user_name l2"
                                                *ngIf="((selectedIndex=='1' || selectedIndex=='2') &&((ride_status=='0' && is_active == '0')||(request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' )) && !isCompleted) ">No
                                                Available Driver within Range</label>
                                        </div>
                                        <div class="col-auto">

                                            <img src="{{driver_image}}" style="border-radius:50%;"
                                                class="center-block image" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-4"
                                        ng-hide="((selectedIndex=='1' || selectedIndex=='2') &&((ride_status=='0' && is_active == '0')||(request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' ) ))">
                                        <label class="user_type l1">Dispatching Car Base</label> <br>
                                        <label class="user_name l2">
                                            {{cropName(business_name, 15)}}<br>{{car_base_mobile}}</label>
                                    </div>
                                    <div class="col-auto px-4"
                                        ng-hide="((selectedIndex=='1' || selectedIndex=='2') &&((ride_status=='0' && is_active == '0')||(request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' ) ))">
                                        <label class="user_type l1">Car</label> <br>
                                        <label class="user_name l2">
                                            {{car_name}}<br>{{car_make | titlecase}} {{car_model | titlecase}} {{car_color | titlecase}} {{car_year}}<br>{{car_no}}</label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="active_trip_tab == 1 && !retryRide" class="mt-4">
                            <div class="row col-md-12 mt-4 mb-3 px-2">
                                <div class="col-auto pl-0 mr-auto">
                                    <div class="col-auto mb-2">
                                        <label class="l1"> Date/Time of Request Sent </label><br>
                                        <label class="l2" *ngIf="reqdate!=''">{{reqdate|date:'MM/dd/yyyy'}}
                                            {{reqdate|date:'hh:mm a'}} ({{datestringtz | timezone}})</label><br>
                                    </div>
                                    <div class="col-auto mb-2" *ngIf="selectedIndex=='1' || is_schedule==1">
                                        <label class="l1">Date/Time of Request Accepted</label><br>
                                        <label class="l2" *ngIf="acptdate">{{acptdate|date:'MM/dd/yyyy'}}
                                            {{acptdate|date:'hh:mm a'}} ({{datestringtz | timezone}})
                                        </label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Date/Time of Pickup </label><br>
                                        <label class="l2"
                                            *ngIf="is_schedule==0 && pickdate">{{pickdate|date:'MM/dd/yyyy'}}
                                            {{pickdate|date:'hh:mm a'}} ({{datestringtz | timezone}})
                                        </label>
                                        <label class="l2" *ngIf="is_schedule==1 && pick_up">{{pick_up}}
                                            {{pick_up_time|uppercase}} ({{datestringtz | timezone}})
                                        </label>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-auto my-3 px-2" [hidden]="!requestLoader" *ngIf="(driverNames=='')">
                                <label class="l1">LOADING..
                                    <i class="fa fa-spinner fa-spin" style="font-size:130%"></i>
                                </label><br>
                            </div>

                            <ng-container *ngFor="let data of requestdata;let i=index">
                                <div class="">
                                    <div class="col-auto px-4" [ngClass]="{'pt-3' : i === 0}"
                                        style="margin-bottom: -10px;">
                                        <label class="l1">{{i+1 | ordinal}} Request Sent to</label>&nbsp;
                                        <label style="font-size: 9px;font-weight: 400;color: #6b6d70;">{{data.sent_at |
                                            date:'HH:mm a'}} ({{data.offset | timezone}}) </label><br>
                                        <div *ngIf="data.requestdataarray && !data.requestdataarray.length">
                                            <label *ngIf="!req" class="l2">No
                                                Available Driver within Range</label>
                                        </div>
                                        <div *ngFor="let req of data.requestdataarray">
                                            <label
                                                class="l2">{{cropName(req.split('+')[0],25)}}+{{req.split('+')[1]}}</label>&nbsp;
                                            <label *ngIf="data.business_name" class="l1"
                                                style="font-size: 11px">{{cropName(data.business_name,15)}}</label>
                                            <ng-container *ngIf="data.business_name"><br></ng-container>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div *ngIf="driver_name" class="col-auto mb-2 my-3 px-4">
                                <label class="l1">ACCEPTED:</label> <br>
                                <label class="l2">{{cropName(driver_name,25)}} {{driver_mobile}}</label><br>
                                <label *ngIf="business_name" class="l2">{{cropName(business_name,15)}}</label>
                                <br>
                            </div>
                        </div>

                        <div *ngIf="active_trip_tab == 2 && !retryRide" class="mt-4">
                            <div class="row col-md-12 mt-4 mb-3 px-2">
                                <div class="col-auto pl-0 mr-auto">
                                    <div class="col-auto mb-2">
                                        <label class="l1"> Date/Time of Request Sent </label><br>
                                        <label class="l2" *ngIf="reqdate!=''">{{reqdate|date:'MM/dd/yyyy'}}
                                            {{reqdate|date:'hh:mm a'}} {{datestringtz | timezone}}</label>
                                        <label class="l2" *ngIf="!reqdate">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Date/Time of Request Accepted</label><br>
                                        <label class="l2" *ngIf="accept_time">{{accept_time|date:'MM/dd/yyyy'}}
                                            {{accept_time|date:'hh:mm a'}} {{datestringtz | timezone}}
                                        </label>
                                        <label class="l2" *ngIf="!accept_time">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Accepted Location </label><br>
                                        <label class="l2" *ngIf="accept_location_address">
                                            {{accept_location_address}}
                                        </label>
                                        <label class="l2" *ngIf="!accept_location_address">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Accepted By </label><br>
                                        <label class="l2" *ngIf="driver_name">
                                            {{cropName(driver_name,25)}} {{driver_mobile}}
                                        </label>
                                        <label class="l2" *ngIf="!driver_name">- -</label><br>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row col-md-12 mt-4 mb-3 px-2">
                                <div class="col-auto pl-0 mr-auto">
                                    <div class="col-auto mb-2">
                                        <label class="l1"> Date/Time of Starting Pickup </label><br>
                                        <label class="l2" *ngIf="accept_time">
                                            {{accept_time|date:'MM/dd/yyyy'}} {{accept_time|date:'hh:mm a'}}
                                            {{datestringtz | timezone}}
                                        </label>
                                        <label class="l2" *ngIf="!accept_time">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Starting Pickup Location</label><br>
                                        <label class="l2"
                                            *ngIf="accept_location_address">{{accept_location_address}}</label>
                                        <label class="l2" *ngIf="!accept_location_address">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Date/Time Arrived </label><br>
                                        <label class="l2" *ngIf="arrival_time">
                                            {{arrival_time|date:'MM/dd/yyyy'}} {{arrival_time|date:'HH:mm a'}}
                                            {{datestringtz | timezone}}
                                        </label>
                                        <label class="l2" *ngIf="!arrival_time">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Arrived Location </label><br>
                                        <label class="l2" *ngIf="arrive_location">{{arrive_location}}</label>
                                        <label class="l2" *ngIf="!arrive_location">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1"> Rider Request Pickup Location </label><br>
                                        <label class="l2"
                                            *ngIf="pickup_location_address">{{pickup_location_address}}</label>
                                        <label class="l2" *ngIf="!pickup_location_address">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Date/Time Start Trip</label><br>
                                        <label class="l2" *ngIf="start_time">
                                            {{start_time|date:'MM/dd/yyyy'}} {{start_time|date:'HH:mm a'}}
                                            {{datestringtz | timezone}}
                                        </label>
                                        <label class="l2" *ngIf="!start_time">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Start Trip Location </label><br>
                                        <label class="l2" *ngIf="start_location">{{start_location}}</label>
                                        <label class="l2" *ngIf="!start_location">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Date/Time End Trip</label><br>
                                        <label class="l2" *ngIf="drop_time">
                                            {{drop_time|date:'MM/dd/yyyy'}} {{drop_time|date:'HH:mm a'}} {{datestringtz
                                            | timezone}}
                                        </label>
                                        <label class="l2" *ngIf="!drop_time">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">End Trip Location</label><br>
                                        <label class="l2" *ngIf="drop_address">{{drop_address}}</label>
                                        <label class="l2" *ngIf="!drop_address">- -</label><br>
                                    </div>
                                    <div class="col-auto mb-2">
                                        <label class="l1">Rider Requested Drop off Location</label><br>
                                        <label class="l2"
                                            *ngIf="manual_destination_address">{{manual_destination_address}}</label>
                                        <label class="l2" *ngIf="!manual_destination_address">- -</label><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="retryRide">
                            <div class="mb-3">
                                <div class="row col-md-12 mt-4 px-2">
                                    <div class="col-auto mr-auto">
                                        <label class="l1">Passenger</label><br>
                                        <label class="l2">{{cropName(user_name, 25)}}<br>{{user_mobile}}</label>
                                    </div>
                                    <div class="col-auto">
                                        <img src="{{user_image}}" *ngIf="user_image!=''" style="border-radius:50%;"
                                            class="center-block image" />
                                        <img src="http://qudos-s3.s3.amazonaws.com/user_profile/user.png"
                                            *ngIf="user_image==''" style="border-radius:50%; "
                                            class="center-block image dummy" />
                                    </div>
                                </div>
                                <div class="row col-md-12 py-2 px-2">
                                    <div class="col-auto mr-auto" style="font-size: 12px;">
                                        <i class="fa fa-car" aria-hidden="true"></i>&nbsp;
                                        <img src="assets/images/divider_black.svg" alt="divider_black">&nbsp;&nbsp;
                                        {{car_name}}
                                    </div>
                                    <div class="col-auto"></div>
                                </div>
                                <hr>
                                <div class="row col-md-12 px-2">
                                    <div class="col-auto mr-auto" style="font-size: 12px;">
                                        <i class="fa fa-crosshairs" aria-hidden="true"></i>&nbsp;&nbsp;
                                        <img src="assets/images/divider_black.svg" alt="divider_black">&nbsp;&nbsp;
                                        <mat-form-field appearance="standard">
                                            <mat-label>Pickup Location</mat-label>
                                            <input matInput placeholder="Please Enter Pickup Location"
                                                [(ngModel)]="pickup_location_address" disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr>
                                <div class="row col-md-12 px-2">
                                    <div class="col-auto mr-auto" style="font-size: 12px;">
                                        <i class="fa fa-crosshairs" aria-hidden="true"></i>&nbsp;&nbsp;
                                        <img src="assets/images/divider_black.svg" alt="divider_black">&nbsp;&nbsp;
                                        <mat-form-field appearance="standard">
                                            <mat-label>Dropoff Location</mat-label>
                                            <input matInput placeholder="Please Enter Dropoff Location"
                                                [(ngModel)]="manual_destination_address" disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr>
                                <div class="row col-md-12 mt-2 px-2">
                                    <div class="col-auto" style="font-size: 12px;">
                                        <mat-checkbox style="font-size: 10px;" [checked]="consentChecked"
                                            (change)="consentChange($event)">
                                            I confirm to adhere to the latest CDC policies to stay
                                            informed, review health and safety measures taken
                                            about COVID-19
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-shadow middileBarBottomStyle">
                    <hr>
                    <div class="mt-2 mr-2 ml-2 button-wrapper">
                        <div class="row">
                            <ng-container *ngIf="!rideInFocus?.next_milestone">
                                <div class="col"
                                    *ngIf="(selectedIndex=='1') && ((request_status=='0') || (ride_status=='1') || (ride_status=='2') || (request_status=='1' && ride_status=='0' && is_active=='1'))">
                                    <button type="button" (click)="cancelpopup(rideInFocus)" class="btn btn-secondary button-black">
                                        Cancel Ride
                                    </button>
                                </div>
                                <div class="col"
                                    *ngIf="(selectedIndex=='2') && ((( ride_status=='0' && is_active == '1') || (is_active == '1' &&  ride_status == '0' )) )">
                                    <button type="button" (click)="cancelpopup(rideInFocus)" class="btn btn-secondary button-black">
                                        Cancel Ride
                                    </button>
                                </div>
                                <div class="col" *ngIf="(selectedIndex=='2') && ((ride_status=='2') || (ride_status=='1') || (ride_status=='3') )">
                                    <button type="button" (click)="cancelpopup(rideInFocus)" class="col btn btn-secondary button-black">
                                        Cancel Ride
                                    </button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='2' && ride_status=='2'">
                                    <button type="button" [disabled]="isStarted" (click)="showstartpopup()" class="col btn btn-primary">
                                        Start Trip
                                    </button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='1' && ride_status=='2'">
                                    <button type="button" [disabled]="isStarted" (click)="showstartpopup()" class="col btn btn-primary">
                                        Start Trip
                                    </button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='1' && ride_status=='1'">
                                    <button type="button" class="col btn btn-primary" (click)="showArrivedPopup()">Arrived</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='2' && ride_status=='1'">
                                    <button type="button" class="col btn btn-primary" (click)="showArrivedPopup()">Arrived</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='1' && ride_status=='3' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='1' && ride_status=='2' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='1' && ride_status=='1' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='2' && ride_status=='3' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='2' && ride_status=='2' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                                <div class="col" *ngIf="selectedIndex=='2' && ride_status=='1' && !isCompleted">
                                    <button type="button" class="col btn btn-primary" (click)="complete(rideInFocus)">Complete</button>
                                </div>
                            </ng-container>
                            <!-- Next Milestone Button -->
                            <ng-container *ngIf="rideInFocus?.next_milestone && selectedIndex === 2">
                                <div class="col">
                                    <button type="button" class="col btn text-white"
                                    (click)="confirm_milestone_update(rideInFocus?.mas_trip_id, rideInFocus?.next_milestone)"
                                        [ngClass]="rideInFocus?.ride_milestone_class"
                                    >{{ rideInFocus?.next_milestone_text }}</button>
                                </div>
                                <div class="col" *ngIf="rideInFocus?.next_milestone === 3">
                                    <button class="animate-show btn btn_css text-white btn-danger" (click)="confirm_milestone_update(rideInFocus?.mas_trip_id, 6)">
                                        No Show ?
                                    </button>
                                </div>
                            </ng-container>
                            <div class="col" *ngIf="(selectedIndex=='0' || selectedIndex=='1' || selectedIndex=='2')
                                    && !retryRide && ((request_status=='10' && ride_status=='0' && is_active == '0')
                                        || (is_active == '0' && request_status == null && ride_status == '0' )
                                        || (is_active == '0' && request_status == null && ride_status == '11' )
                                        || (is_active == '0' && request_status == null && ride_status == '9' )
                                        || (is_active == '0' && request_status == null && ride_status == '8' )
                                        || (is_active == '0' && request_status == null && ride_status == '7' )
                                        || (is_active == '0' && request_status == null && ride_status == '5' )
                                        || (is_active == '0' && request_status == null && ride_status == '6' )
                                        || (is_active == '0' && request_status == '7' && ride_status == '7' )
                                        || (is_active == '0' && request_status == '9' && ride_status == '9' )
                                        || (is_active == '0' && request_status == '11' && ride_status == '11' )
                                        || (is_active == '0' && request_status == '5' && ride_status == '5' ) 
                                        || (ride_status == '4' || request_status == '4'))">
                                <button type="button" class="col btn btn-full"
                                    style="background-color: black;color: white" (click)="createTicket()">Create
                                    Ticket</button>
                            </div>
                            <div class="col" *ngIf="(activeRideEnum[1] === activeRideType || activeRideEnum[2] === activeRideType )
                                        && !retryRide && (ride_status == '6' ||  ride_status == '7'
                                        ||  ride_status == '9' || ride_status == '11' || ride_status == '5')">
                                <button type="button" class="col btn btn-primary" (click)="setRetryRide()">Retry Ride
                                    Request</button>
                            </div>
                            <div class="col"*ngIf="(activeRideEnum[1] === activeRideType || activeRideEnum[2] === activeRideType )
                                    && retryRide && (ride_status == '6' ||  ride_status == '7'
                                        ||  ride_status == '9' || ride_status == '11' || ride_status == '5')">
                                <button type="button" class="col btn btn-full"
                                    style="background-color: black;color: white"
                                    (click)="setRetryRide()">Cancel</button>
                            </div>
                            <div class="col" *ngIf="(activeRideEnum[1] === activeRideType || activeRideEnum[2] === activeRideType )
                            && retryRide && (ride_status == '6' ||  ride_status == '7'
                                ||  ride_status == '9' || ride_status == '11' || ride_status == '5')">
                                <button type="button" class="col btn btn-primary" (click)="confirmRetryRide()">Send Ride
                                    Request</button>
                            </div>
                            <div class="col" *ngIf="(selectedIndex=='0' || selectedIndex=='1' || selectedIndex=='2')
                                    && !retryRide && ticket_count && ((request_status=='10' && ride_status=='0' && is_active == '0')
                                        || (is_active == '0' && request_status == null && ride_status == '0' )
                                        || (is_active == '0' && request_status == null && ride_status == '11' )
                                        || (is_active == '0' && request_status == null && ride_status == '9' )
                                        || (is_active == '0' && request_status == null && ride_status == '8' )
                                        || (is_active == '0' && request_status == null && ride_status == '7' )
                                        || (is_active == '0' && request_status == null && ride_status == '5' )
                                        || (is_active == '0' && request_status == null && ride_status == '6' )
                                        || (is_active == '0' && request_status == '7' && ride_status == '7' )
                                        || (is_active == '0' && request_status == '9' && ride_status == '9' )
                                        || (is_active == '0' && request_status == '11' && ride_status == '11' )
                                        || (is_active == '0' && request_status == '5' && ride_status == '5' ) 
                                        || (ride_status == '4' || request_status == '4'))">
                                <button type="button" class="col btn btn-primary" (click)="viewTicket()">View
                                    Ticket</button>
                            </div>
                            <div class="col" *ngIf=" activeRideEnum[1] === activeRideType && ride_status=='3'">
                                <button type="button" style="background-color: black;color: white"
                                    class="col btn  btn-full" (click)="cancelpopup(rideInFocus)">Cancel and
                                    Refund</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- </div> -->
            </div>
        </div>

        <div id="mapCustom" class="col-6 rightSideBar card-shadow">
            <div class="page-nopad map-control row">
                <div class="col" style="padding:5px">
                    <div class="row map-input-container card-shadow">
                        <div class="col-auto left-icon p-1 pl-3">
                            <img src="assets/images/drop_location.svg" alt="location_icon">
                            &nbsp;
                            <img src="assets/images/divider_black.svg" alt="divider_black">
                        </div>
                        <div class="col p-1">
                            <input id="searchLocation" style="padding-top:5px;padding-bottom:5px"
                                class="borderless-input autocomplete" type="text" placeholder="Location" ng-autocomplete
                                [(ngModel)]="SelectedLocation" name="location" (keypress)="searchLocation()">
                        </div>
                        <div class="col-auto right-icon pt-1">
                            <i class="fa fa-search"></i>
                        </div>
                    </div>

                </div>
                <div class="col" style="padding:5px">
                    <div class="row map-input-container card-shadow">
                        <div class="col-auto left-icon p-1 pl-3">
                            <i class="fa fa-angle-left cursor-pointer" (click)="previousSearchedDriver()"></i>&nbsp;
                            <img src="assets/images/user.png" alt="prefer_fav_icon">&nbsp;
                            <i class="fa fa-angle-right cursor-pointer" (click)="nextSearchedDriver()"></i>
                            &nbsp;
                            <img src="assets/images/divider_black.svg" alt="divider_black">
                        </div>
                        <div class="col p-1">
                            <input id="searchDriver" style="padding-top:5px;padding-bottom:5px" class="borderless-input"
                                type="text" placeholder="Search Driver" [(ngModel)]="searchdriver" name="searchDriver"
                                (keypress)="searchDriver()">
                        </div>
                        <div class="col-auto right-icon pt-1">
                            <i class="fa fa-search cursor-pointer" (click)="searchDriver()"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div style="border-radius: 5px;" id="map" class="map-b"></div>
            <div class="map-bottom-container p-3 px-4">
                <div class="row">
                    <div class="col-auto pr-0" (click)="showDriverStatusPopup();$event.preventDefault()">
                        <mat-checkbox class="checkBoxDesign" [checked]="onlineType ==1" [disableRipple]="true">
                            Online</mat-checkbox>
                        <mat-checkbox class="checkBoxDesign" [checked]="offlineType ==1" [disableRipple]="true">
                            Offline</mat-checkbox>
                        <mat-checkbox class="checkBoxDesign hideVisibilty" [checked]="allType ==1"
                            [disableRipple]="true">
                            All</mat-checkbox>
                    </div>
                    <div class="col-auto mr-auto">
                        <div class="row">
                            <div class="control-box mr-1" style="font-size: 12px;">
                                <span class="showname" class="span-angle-left" style="cursor: auto;">
                                    <i *ngIf="page === 1" class="fa fa-angle-left"
                                        style="font-size: 12px !important;"></i>
                                    <i *ngIf="page !== 1" class="fa fa-angle-left cursor-pointer"
                                        style="font-size: 12px !important;" (click)="previousDriverList()"></i>
                                </span>

                                {{skip1+ (maxSize ? 1: 0)}} - {{maxSize > skip1 + limit ? skip1 + limit : maxSize}} of
                                {{maxSize}}

                                <span class="showname" class="span-angle-right" style="cursor: auto;">
                                    <i *ngIf="maxSize > skip1 + limit" class="fa fa-angle-right cursor-pointer"
                                        style="font-size: 12px !important;" (click)="nextDriverList()"></i>
                                    <i *ngIf="maxSize <= skip1 + limit" class="fa fa-angle-right"
                                        style="font-size: 12px !important;"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="row">
                            <div class="control-box mr-1">
                                <span class="showname" [ngClass]="{'span-active' : toggle === true}"
                                    (click)="toggle = true"> ON </span>
                                <span class="showname" [ngClass]="{'span-inactive' : toggle === false}"
                                    (click)="toggle = false">OFF</span>
                            </div>
                            <div class="control-box mr-1">
                                <span class="highlight" (click)="setZoom(false, true)">
                                    <i aria-hidden="true" class="fa fa-minus"></i>
                                </span>
                                <span>|</span><span class="highlight" (click)="setZoom(true, false)">
                                    <i aria-hidden="true" class="fa fa-plus">
                                    </i></span>
                            </div>
                            <div class="control-box fullscreen-control">
                                <span (click)="initFullscreenControl()">
                                    <img src="assets/images/scan_tag.svg" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="cancelpopup" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="ml-5 mb-2">Alert</div>
                    <div style="text-align: center;">
                        <p class="modal-text" *ngIf="selectedIndex=='1'">Do you want to
                            cancel the scheduled ride ?</p>
                        <p class="modal-text" *ngIf="selectedIndex=='2'">Do you want to
                            cancel the regular ride ?</p>
                    </div>
                    <div style="text-align: center;" class="logoutbtn">
                        <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hidecancelpopup()"
                            type="submit"
                            style="padding:7px 33px 7px 33px; margin-right:7px;background: #aaa;font-size: 12px;">Cancel</button>
                        <button class="btn btn-primary login_button" data-dismiss="modal" (click)="cancelRide()"
                            type="submit"
                            style="padding:7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;color: white;font-size: 12px;">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="completepopup" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="ml-5 mb-2">Alert</div>
                    <div style="text-align:center;">
                        <p class="modal-text" *ngIf="selectedIndex=='1'">Do you want to
                            complete the scheduled ride ?</p>
                        <p class="modal-text" *ngIf="selectedIndex=='2'">Do you want to
                            complete the regular ride ?</p>
                    </div>
                    <div style="text-align:center;" class="logoutbtn">
                        <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hidecompletepopup()"
                            type="submit"
                            style="padding:7px 33px 7px 33px; margin-right:7px;background: #aaa;font-size: 12px;">Cancel</button>
                        <button class="btn btn-primary login_button" data-dismiss="modal" (click)="completeRide()"
                            type="submit"
                            style="padding: 7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;color: white;font-size: 12px;">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="startpopup" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="ml-5 mb-2">Alert</div>
                    <div style="text-align:center;">
                        <p class="modal-text" *ngIf="selectedIndex=='1'">Do you want to
                            start the scheduled ride ?</p>
                        <p class="modal-text" *ngIf="selectedIndex=='2'">Do you want to
                            start the regular ride ?</p>
                    </div>
                    <div style="text-align:center;" class="logoutbtn">
                        <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hidestartpopup()"
                            type="submit"
                            style="padding:7px 33px 7px 33px; margin-right:7px;background: #aaa;font-size: 12px;">Cancel</button>
                        <button class="btn btn-primary login_button" data-dismiss="modal" (click)="startRide()"
                            type="submit"
                            style="padding: 7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;color: white;font-size: 12px;">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="createTicket" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 571px !important;">
            <!-- Modal content-->
            <div class="modal-content" style="height: 470px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader">Add New Ticket</p>
                </div>
                <div class="modal-body" style="background-color: #f8fafc;">
                    <div class="row mt-3">
                        <div class="col-4">
                            <label class="createTicketFieldText">Rider Info</label>
                        </div>
                        <div class="col-8">
                            <input style="padding: 0px 0px 3px 5px;" placeholder="Auto filled" [(ngModel)]="user_name"
                                disabled class="form-control createTicketFieldBox" name="rider" id="rider" required>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Driver Info</label>
                        </div>
                        <div *ngIf="driver_name" class="col-8"><input style="padding: 0px 0px 3px 5px;"
                                placeholder="Auto filled" [(ngModel)]="driver_name" disabled
                                class="form-control createTicketFieldBox" name="driver" id="driver" required></div>
                        <div *ngIf="!driver_name && ((selectedIndex=='1') &&((request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' ) ))"
                            class="col-8"><input style="padding: 0px 0px 3px 5px;" [(ngModel)]="driver_name2" disabled
                                class="form-control createTicketFieldBox" name="driver" id="driver" required></div>
                        <div *ngIf="!driver_name && !((selectedIndex=='1') &&((request_status=='10' && ride_status=='0' && is_active == '0')	|| (is_active == '0' && request_status == null && ride_status == '0' ) ))"
                            class="col-8"><input style="padding: 0px 0px 3px 5px;" placeholder="Enter Driver Info"
                                [(ngModel)]="driver_name2" class="form-control createTicketFieldBox" name="driver"
                                id="driver" required></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Ticket Type</label>
                        </div>
                        <div class="col-8">
                            <select name="type" style="padding: 0px 0px 0px 0px;" id="type" [(ngModel)]="ticket.type"
                                class="form-control createTicketFieldBox">
                                <option value="" disabled selected>Ticket Type</option>
                                <option [value]="1">Ride Payment</option>
                                <option [value]="2">Ride Payout</option>
                                <option [value]="3">Lost Item</option>
                                <option [value]="4">Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2 ">
                        <div class="col-4">
                            <label class="createTicketFieldText">Select Status</label>
                        </div>
                        <div class="col-8">
                            <select name="status" style="padding: 0px 0px 0px 0px;" [(ngModel)]="ticket.status"
                                id="status" class="form-control createTicketFieldBox">
                                <option value="" disabled selected>Select Status</option>
                                <option [value]="1">Ongoing</option>
                                <option [value]="2">Unassigned</option>
                                <option [value]="3">Closed</option>
                                <option [value]="4">High Priority</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Trip Info</label>
                        </div>
                        <div class="col-8"><input style="padding: 0px 0px 3px 5px;" placeholder="Auto filled"
                                [(ngModel)]="tripnumber" disabled class="form-control createTicketFieldBox" name="trip"
                                id="trip" required></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Description</label>
                        </div>
                        <div class="col-8">
                            <textarea rows="4" [(ngModel)]="ticket.description"
                                class="form-control createTicketFieldBox"
                                style="padding:0px 0px 0px 5px;background: #FFFFFF;border: 1px solid #EBEFF2;box-sizing: border-box; height:auto;"
                                name="description" id="description" placeholder="Type description here"
                                required></textarea>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideCreateTicketpopup()"
                        type="submit"
                        style="padding:4px 20px 4px 20px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit" (click)="createRideTicket()"
                        style="padding: 4px 20px 4px 20px;margin-right:90px;border-radius: 4px;color: white;font-size: 12px;">Create
                        Ticket</button>
                </div>
            </div>
        </div>
    </div>

    <div id="viewTicket" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 771px !important;">
            <!-- Modal content-->
            <div class="modal-content" style="height: 470px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader" style="margin: 0px 0px 0px 17px">Tickets</p>
                </div>
                <div class="modal-body scrollBarViewTicket">
                    <ng-container *ngFor="let ticketDetail of tickets; let i=index">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-8 px-5">
                                    <div style="font-weight: 510; font-size: 16px; width: auto;">TICKET INFORMATION
                                        <span style="font-weight: 510; font-size: 13px;width: auto;">
                                            &nbsp;&nbsp;#{{ticketDetail.session_id}}
                                        </span>
                                        <a style="color:#1f8dc6;font-weight: 510; font-size: 11px;width: auto;"
                                            (click)="ticketDetail.show = !ticketDetail.show">
                                            &nbsp;&nbsp;{{ !ticketDetail.show ? 'Show Less': 'Show More' }}
                                        </a>
                                    </div>
                                </div>
                                <div class="col ml-5">
                                    <button class="btn editDetailsButton editDetailsText"
                                        (click)="hideViewTicketPopup();editTicket(ticketDetail)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                        Edit Details</button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2 px-5">
                                    <label class="activeMeesageLabel" style="font-weight: 510; font-size: 13px;">Rider
                                        Info
                                    </label>
                                </div>
                                <div class="col-2">
                                    <span class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;">{{ticketDetail.user_name}}
                                    </span>
                                </div>
                                <div class="col-2 ml-5"><label class="activeMeesageLabel"
                                        style="font-weight: 510; font-size: 13px;width: auto;">Status
                                    </label>
                                </div>
                                <div [ngSwitch]="ticketDetail.status" class="col-2">
                                    <span *ngSwitchCase="1" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Ongoing</span>
                                    <span *ngSwitchCase="2" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Unassigned</span>
                                    <span *ngSwitchCase="3" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Closed</span>
                                    <span *ngSwitchCase="4" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> High Priority</span>
                                </div>
                            </div>

                            <div class="row mt-1" [class.show]="ticketDetail.show">
                                <div class="col-2 px-5">
                                    <label class="activeMeesageLabel" style="font-weight: 510; font-size: 13px;">Driver
                                        Info
                                    </label>
                                </div>
                                <div class="col-2">
                                    <span class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;">
                                    </span>
                                </div>
                                <div class="col-2 ml-5"><label class="activeMeesageLabel"
                                        style="font-weight: 510; font-size: 13px;width: auto;">Type
                                    </label>
                                </div>
                                <div [ngSwitch]="ticketDetail.type" class="col-2">
                                    <span *ngSwitchCase="1" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Ride Payment</span>
                                    <span *ngSwitchCase="2" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Ride Payout</span>
                                    <span *ngSwitchCase="3" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Lost Item</span>
                                    <span *ngSwitchCase="4" class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto;"> Others</span>
                                </div>
                            </div>

                            <div class="row mt-1" style="overflow: visible;" [class.show]="ticketDetail.show">
                                <div class="col-2 px-5">
                                    <label class="activeMeesageLabel"
                                        style="font-weight: 510; font-size: 13px;width: auto;">Description</label>
                                </div>
                                <div class="col-6">
                                    <span class="activeMeesageText pl-3"
                                        style="font-weight: 510; font-size: 11px;width: auto; word-break: break-all;height: auto;">{{ticketDetail.description}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="i !== tickets.length -1">
                    </ng-container>
                </div>
                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideViewTicketPopup()"
                        type="submit"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit"
                        (click)="hideViewTicketPopup();createTicket()"
                        style="padding: 7px 33px 7px 33px;margin-right:50px;border-radius: 4px;color: white;font-size: 12px;">Create
                        Ticket</button>
                </div>
            </div>
        </div>
    </div>

    <div id="editTicket" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 571px !important;">
            <!-- Modal content-->
            <div class="modal-content" style="height: 470px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader">Edit Ticket</p>
                </div>
                <div class="modal-body" style="background-color: #f8fafc;">
                    <div class="row mt-3">
                        <div class="col-4">
                            <label class="createTicketFieldText">Rider Info</label>
                        </div>
                        <div class="col-8">
                            <input style="padding: 0px 0px 3px 5px;" placeholder="Auto filled" disabled
                                [(ngModel)]="selectedTicket.user_name" class="form-control createTicketFieldBox"
                                name="rider" id="rider2" required>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Driver Info</label>
                        </div>
                        <div class="col-8"><input style="padding: 0px 0px 3px 5px;" placeholder="Auto filled" disabled
                                class="form-control createTicketFieldBox" name="driver" id="driver2" required></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Ticket Type</label>
                        </div>
                        <div class="col-8">
                            <select name="type" style="padding: 0px 0px 0px 0px;" id="type"
                                [(ngModel)]="selectedTicket.type" class="form-control createTicketFieldBox">
                                <option value="" disabled selected>Ticket Type</option>
                                <option [value]="1">Ride Payment</option>
                                <option [value]="2">Ride Payout</option>
                                <option [value]="3">Lost Item</option>
                                <option [value]="4">Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2 ">
                        <div class="col-4">
                            <label class="createTicketFieldText">Select Status</label>
                        </div>
                        <div class="col-8">
                            <select name="status" style="padding: 0px 0px 0px 0px;" id="status"
                                [(ngModel)]="selectedTicket.status" class="form-control createTicketFieldBox">
                                <option value="" disabled selected>Select Status</option>
                                <option [value]="1">Ongoing</option>
                                <option [value]="2">Unassigned</option>
                                <option [value]="3">Closed</option>
                                <option [value]="4">High Priority</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Trip Info</label>
                        </div>
                        <div class="col-8"><input style="padding: 0px 0px 3px 5px;" placeholder="Auto filled" disabled
                                [(ngModel)]="tripnumber" class="form-control createTicketFieldBox" name="trip"
                                id="trip2" required></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label class="createTicketFieldText">Description</label>
                        </div>
                        <div class="col-8">
                            <textarea rows="4" class="form-control createTicketFieldBox"
                                [(ngModel)]="selectedTicket.description"
                                style="padding:0px 0px 0px 5px;background: #FFFFFF;border: 1px solid #EBEFF2;box-sizing: border-box; height:auto;"
                                name="description" id="description" placeholder="Type description here"
                                required></textarea>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideEditTicketPopup()"
                        type="submit"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit" (click)="editRideTicket()"
                        style="padding: 7px 33px 7px 33px;margin-right:50px;border-radius: 4px;color: white;font-size: 12px;">Edit
                        Ticket</button>
                </div>
            </div>
        </div>
    </div>

    <div id="confirmRetryRide" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 571px !important;">
            <!-- Modal content-->
            <div class="modal-content" style="height: 300px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader" style="margin: 10px 0px 0px 15px;width: 223px;">CDC Policy Compliance
                    </p>
                </div>
                <div class="modal-body" style="background-color: #f8fafc;">
                    <div class="row mt-3">
                        <div class="px-5">
                            <p>By clicking on the "Confirm" button, I confirm to adhere to
                                the latest CDC policies to stay informed, review health and
                                safety measures taken about COVID-19
                            </p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideRetryRide()"
                        type="submit"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit" (click)="retryRideCall()"
                        style="padding: 7px 33px 7px 33px;margin-right:50px;border-radius: 4px;color: white;font-size: 12px;">Confirm</button>
                </div>
            </div>
        </div>
    </div>

    <div id="confirmMilestoneUpdate" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 571px !important;">
            <div class="modal-content" style="height: 300px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader" style="margin: 10px 0px 0px 15px;width: 223px;">
                        {{ confirm_milestone_config?.heading }}
                    </p>
                </div>
                <div class="modal-body" style="background-color: #f8fafc;">
                    <div class="row mt-3">
                        <div class="px-5">
                            <p>
                                {{ confirm_milestone_config?.description }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" type="submit"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit" (click)="update_mas_assign_milestone()"
                        [disabled]="confirm_milestone_config?.loading"
                        style="padding: 7px 33px 7px 33px;margin-right:50px;border-radius: 4px;color: white;font-size: 12px;">
                        <ng-container *ngIf="!confirm_milestone_config?.loading">Yes</ng-container>
                        <ng-container *ngIf="confirm_milestone_config?.loading">Updating...</ng-container>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="arrivedpopup" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="ml-5 mb-2">Alert</div>
                    <div style="text-align:center;">
                        <p class="modal-text">Are you sure you want to proceed ?</p>
                    </div>
                    <div style="text-align:center;" class="logoutbtn">
                        <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideArrivedPopup()"
                            type="submit"
                            style="padding:7px 33px 7px 33px; margin-right:7px;background: #aaa;font-size: 12px;">Cancel</button>
                        <button class="btn btn-primary login_button" data-dismiss="modal" (click)="arrivedRide()"
                            type="submit"
                            style="padding: 7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;color: white;font-size: 12px;">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="driverInfo" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 800px !important;">
            <!-- Modal content-->
            <div class="modal-content" style="height: 500px">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="driverInfo">INFORMATION</p>
                </div>

                <div class="modal-body" style="background-color: #f8fafc;">
                    <div class="row">
                    <p class="driverInfoHeader">DRIVER & RIDER INFORMATION </p>
                </div>
                    <div class="row mt-3">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Rider Info</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.rider_info}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Type/Seats</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{dirverDetails.type_seat}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Driver Info</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{dirverDetails.driver_info}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Vehicle</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{dirverDetails.vehicle}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Driver's Phone #</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{dirverDetails.driver_mobile}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Color</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{dirverDetails.car_color | uppercase}}</span>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <p class="rideInfoHeader">RIDE INFORMATION</p>
                        </div>
                        <div class="col-6">
                                <button class="btn" *ngIf="!showRideDetails" (click)="getRideDetails(rideDetails.trip_id)" style="border: solid;
                                border-color: lightgrey;border-width: 1px;float: right;
                                margin: 0px 23px 0px 0px;">
                                More Info</button>
                                <button class="btn" *ngIf="showRideDetails" (click)="showRideDetails = false" style="border: solid;
                                border-color: lightgrey;border-width: 1px;float: right;
                                margin: 0px 23px 0px 0px;">
                                Less Info</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Status</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{getButtonText(rideDetails.ride_status,rideDetails.request_status,rideDetails.is_active)}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Pickup Date</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.pickup_real_time |date:'MM/dd/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Trip ID Info</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.trip_id}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Pickup Time</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.pickup_real_time | date:'HH:mm a'}}</span>
                        </div>
                    </div>
                    <div *ngIf="showRideDetails" class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Vehicle</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.vehicle}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Drop Date</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.drop_time |date:'MM/dd/yyyy'}}</span>
                        </div>
                    </div>
                    <div *ngIf="showRideDetails" class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">User Email</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.user_email}}</span>
                        </div>
                        <div class="col-3">
                            <label class="driverInfoFieldText">Drop Time</label>
                        </div>
                        <div class="col-3 driverDetailText">
                            <span >{{rideDetails.drop_time | date:'HH:mm a'}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Pickup</label>
                        </div>
                        <div class="col-9 driverDetailText">
                            <span >{{rideDetails.pickup}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label class="driverInfoFieldText">Drop off</label>
                        </div>
                        <div class="col-9 driverDetailText">
                            <span >{{rideDetails.drop_off}}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-primary login_button" data-dismiss="modal" (click)="hideDriverInfo()"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div id="driverStatus" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 500px !important;">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                    <p class="createTicketHeader" style="margin: 10px 0px 0px 15px;width: 223px;">Driver Visibilty
                        Options
                    </p>
                </div>
                <div class="modal-body" style="background-color: #f8fafc;">
                    <div style="text-align:center;">
                        <div class="row">
                            <div class="col">
                                <p class="driverStatusText">Online</p>
                            </div>
                            <div class="col">
                                <mat-checkbox class="checkBoxDesign" [checked]="driverStatus.onlineType ==1"
                                    (change)="driverStatus.onlineType == 1 ? driverStatus.onlineType= 0 : driverStatus.onlineType = 1">
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="driverStatusText">Busy</p>
                            </div>
                            <div class="col">
                                <mat-checkbox class="checkBoxDesign" [checked]="driverStatus.busyType ==1"
                                    (change)="driverStatus.busyType == 1 ? driverStatus.busyType= 0 : driverStatus.busyType = 1">
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="driverStatusText">Logout</p>
                            </div>
                            <div class="col">
                                <mat-checkbox class="checkBoxDesign" [checked]="driverStatus.logoutType ==1"
                                    (change)="driverStatus.logoutType == 1 ? driverStatus.logoutType= 0 : driverStatus.logoutType = 1">
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="driverStatusText">Offline</p>
                            </div>
                            <div class="col">
                                <mat-checkbox class="checkBoxDesign" [checked]="driverStatus.offlineType ==1"
                                    (change)="driverStatus.offlineType == 1 ? driverStatus.offlineType= 0 : driverStatus.offlineType = 1">
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="driverStatusText">All</p>
                            </div>
                            <div class="col">
                                <mat-checkbox class="checkBoxDesign" [checked]="driverStatus.allType ==1"
                                    (change)="driverStatus.allType == 1 ? driverStatus.allType= 0 : driverStatus.allType = 1">
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <button class="btn btn-outline login_button" data-dismiss="modal" (click)="hideDriverStatusPopup()"
                        type="submit"
                        style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                    <button class="btn btn-primary login_button" type="submit" (click)="applyDriverStatus()"
                        style="padding: 7px 33px 7px 33px;margin-right:50px;border-radius: 4px;color: white;font-size: 12px;">Apply</button>
                </div>
            </div>
        </div>