<header class="header header--white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="header__content">
                    <a href="index.html" class="header__logo">
                        <object type="image/svg+xml" data="assets/logo--white.svg"></object>
                        <object type="image/svg+xml" data="assets/logo--white.svg"></object>
                    </a>

                    <nav class="header__nav">
                        <div class="header__social">
                            <a href="https://twitter.com/ridequdos"><i class="icon-tw"></i></a>
                            <a href="https://www.facebook.com/QudosRide/"><i class="icon-fb"></i></a>
                            <a href="https://www.linkedin.com/company/ridequdos/"><i class="icon-linkedin"></i></a>
                            <a href="https://www.instagram.com/qudosride/"><i class="icon-instagram"></i></a>
                        </div>
                    </nav>

                    <button class="header__menu" type="button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="sign">
    <div class="container" id="login_container">
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="sign__content">
                    <div class="row">
                        <div class="popup-main-content">
                            <!--Start Inner Row-->
                            <div id="main-login" class="row">
                                <div class="col-md-6">
                                    <h3>Live Map Sign In</h3>
                                    <span style="color: #212529;">Qudos creates the opportunity for our drivers to build
                                        the business they truly want.</span>
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <form name="login">
                                            <label>Email</label>
                                            <div class="row-input">
                                                <input id="email" pattern="word" [(ngModel)]="loginUser.email"
                                                    class="email" type="text" name="email"
                                                    (keyup.enter)="pressLoginEnter($event)" #myEmail="ngModel"
                                                    placeholder="Enter Email" autocomplete="off" required>
                                            </div>
                                            <label>Password </label>
                                            <div class="row-input">
                                                <input id="password" value="" [(ngModel)]="loginUser.password"
                                                    class="password" name="password" type="password"
                                                    placeholder="Enter Password" (keyup.enter)="pressLoginEnter($event)"
                                                    focus="true" autocomplete="off" minlength="6" required>
                                            </div>
                                        </form>
                                        <button class="submit userloginButton mb-2" type="button"
                                            (click)="login()">Login</button>
                                    </div>
                                </div>
                            </div>
                            <!--End inner Row-->
                        </div>
                        <!--End Main Content Popup-->
                    </div>
                    <!--End Row-->
                </div>
            </div>
        </div>
    </div>
</section>