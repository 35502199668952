import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

  constructor() { }

  changeCountrySubject = new Subject<any>();

  changeCountry(values: any): void {
    if (values) {
      this.changeCountrySubject.next(values);
    }
  }
}
