import * as moment from 'moment';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Ticket, Driver, Ride, DriverStatus } from '../../models';
import { GlobalEventService, SocketioService , TrackingService, ToasterService} from '../../services';
import { MileStone_Class, MileStone_Map, Milestone_Update_Confirmation } from 'src/app/models/assigned-trip.interface';
import { MasAssignService } from 'src/app/services/mas-assign.service';
declare let google: any;
declare let $: any;

@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.scss', "./live-tracking-dynamic.component.scss"]
})
export class LiveTrackingComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private trackingService: TrackingService,
    private socketioService: SocketioService,
    private toasterService: ToasterService,
    private globalEventService: GlobalEventService,
    private masAssignService: MasAssignService) {
      this.socketioService.setupSocketConnection();
    }

  all = [];
  map: any;
  car_no: any;
  tickets = [];
  maxSize: any;
  reqdate: any;
  timer: string;
  car_make: any;
  acptdate: any;
  pickdate: any;
  car_year: any;
  car_name: any;
  is_active: any;
  car_color: any;
  car_model: any;
  drop_time: any;
  user_name: any;
  viewerdata: any;
  acptdriver: any;
  user_email: any;
  user_image: any;
  start_time: any;
  pick_up: string;
  session_id = '';
  userDetails: any;
  rideInFocus: any;
  is_schedule: any;
  driver_name: any;
  page: number = 1;
  skip: number = 0;
  pickup_time: any;
  accept_time: any;
  requestedda: any;
  ride_status: any;
  drivercount: any;
  requestdata: any;
  user_mobile: any;
  arrival_time: any;
  driver_image: any;
  driver_name2: any;
  ticket_count: any;
  drop_address: any;
  searchdriver: any;
  skip1: number = 0;
  searchString = '';
  expand: number = 1;
  selectedIn: number;
  tripnumber: string;
  selectedIndex: any;
  estimate_diff: any;
  driver_mobile: any;
  business_name: any;
  limit: number = 100;
  start_location: any;
  ride_essential: any;
  request_status: any;
  corporate_name: any;
  total_with_tax: any;
  scheduledrides = [];
  completedrides = [];
  requestedrides = [];
  car_base_mobile: any;
  arrive_location: any;
  pick_up_time: string;
  datestringtz: string;
  SelectedLocation: any;
  corporate_mobile: any;
  toggle: boolean = true;
  totalItemsScheduled: any;
  booked_by_corporate: any;
  estimate_pickup_time: any;
  isStarted: boolean = false;
  retryRide: boolean = false;
  active_trip_tab: number = 0;
  todayDate: Date = new Date();
  isCompleted: boolean = false;
  rideInFocusIndex: number = 0;
  pickup_location_address: any;
  accept_location_address: any;
  isSubLoading: boolean = false;
  manual_destination_address: any;
  skeltonCount = Array(5).fill(4);
  consentChecked: boolean = false;
  showRideDetails: boolean = false;
  isRegularFilter: boolean = false;
  isOngoingFilter: boolean = false;
  isUpcomingFilter: boolean = false;
  isTripListLoading: boolean = true;
  isScheduledFilter: boolean = false;
  isCancelledFilter: boolean = false;
  isTripDetailLoading: boolean = true;
  token: string = localStorage.getItem('web_access_token');
  searchDate: string = new Date().toISOString().substring(0, 10);
  public get searchFlag() { return this.searchString == '' ? 0 : 1; }
  activeRideType: 'completed' | 'scheduled' | 'on-demand' = 'on-demand';
  onlineType = localStorage.getItem('onlineType') ? Number(localStorage.getItem('onlineType')) : 1;
  busyType = localStorage.getItem('busyType') ? Number(localStorage.getItem('busyType')) : 1;
  offlineType = localStorage.getItem('offlineType') ? Number(localStorage.getItem('offlineType')) : 1;
  logoutType = localStorage.getItem('logoutType') ? Number(localStorage.getItem('logoutType')) : 0;
  allType = localStorage.getItem('allType') ? Number(localStorage.getItem('allType')) : 0;

  /*---- for admin pagination----*/
  query = {
    filter: '',
    order: 'session_id',
    limit: 25,
    page: 1,
    requestType: 0
  };

  /*---- for viewer pagination----*/
  query1 = {
    filter: '',
    order: 'driver_id',
    limit: 25,
    page: 1,
    requestType: 0
  };

  ticket: Ticket = {
    type: '',
    status: '',
    description: ''
  };

  center: { lat: 40.732210, lng: -73.919020 };

  selectedTicket: any = {
    user_name: ''
  };

  activeRideEnum = {
    0: 'completed',
    1: 'scheduled',
    2: 'on-demand'
  };

  dirverDetails: Driver = {
    type_seat: '',
    driver_info: '',
    vehicle: '',
    driver_id: '',
    car_color: '',
    driver_mobile: ''
  };

  rideDetails: Ride = {
    rider_info: '',
    pickup_real_time: '',
    pickup: '',
    drop_off: '',
    trip_id: '',
    request_status: '',
    is_active: '',
    ride_status: '',
    vehicle: '',
    drop_time: '',
    user_email: ''
  };

  driverStatus: DriverStatus = {
    allType: localStorage.getItem('allType') ? Number(localStorage.getItem('allType')) : 0,
    busyType: localStorage.getItem('busyType') ? Number(localStorage.getItem('busyType')) : 1,
    onlineType: localStorage.getItem('onlineType') ? Number(localStorage.getItem('onlineType')) : 1,
    logoutType: localStorage.getItem('logoutType') ? Number(localStorage.getItem('logoutType')) : 0,
    offlineType: localStorage.getItem('offlineType') ? Number(localStorage.getItem('offlineType')) : 1
  }

  private newara: any;
  private rTrip1: any;
  private rTrip2: any;
  private mdes: string;
  private user_id: any;
  private markers = [];
  private datalen: any;
  private skip2: number;
  private timerdate: any;
  private strtloca: string;
  private driver_id: number;
  private bouncelength: any;
  private driver_email: any;
  private activeDriver: any;
  private currentdriver: any;
  private ridesDataTimer: any;
  private tabType: number = 0;
  private rotation: number = 0;
  private totalItemsRegular: any;
  private totalItemsOngoing: any;
  private directionsDisplay: any;
  private directionsService: any;
  private searchedDriverList = [];
  private totalItemsCompleted: any;
  private firstClick: boolean = true;
  private initialView: boolean = true;
  private searchFlagdriver: number = 0;
  private ride_status_text: string = '';
  private UserModel: any = JSON.parse(localStorage.getItem('LivemapModel'));

  setSubFilters(isScheduledFilter, isRegularFilter, isUpcomingFilter, isOngoingFilter, isCancelledFilter, refreshData=true) {
    this.isScheduledFilter = false;
    this.isRegularFilter = false;
    this.isUpcomingFilter = false;
    this.isOngoingFilter = false;
    this.isCancelledFilter = false;
    this.tabType = 0;
    if (isScheduledFilter) {
      this.isScheduledFilter = true;
      this.isSubLoading = true;
      this.tabType = 1;
    }
    if (isRegularFilter) {
      this.isRegularFilter = true;
      this.isSubLoading = true;
      this.tabType = 2;
    }
    if (isUpcomingFilter) {
      this.isUpcomingFilter = true;
      this.isSubLoading = true;
      this.tabType = 2;
    }
    if (isOngoingFilter) {
      this.isOngoingFilter = true;
      this.isSubLoading = true;
      this.tabType = 2;
    }
    if (isCancelledFilter) {
      this.isCancelledFilter = true;
      this.isSubLoading = true;
      this.tabType = 1;
    }
    if (refreshData) this.refreshRidesData();
  }

  ngOnDestroy() {
    this.socketioService.removeAllListeners();
  }

  dateChange($event: any): void {
    var date = new Date($event.target.value)
    date.setDate(date.getDate() + 1)
    this.searchDate = date.toISOString().substring(0, 10);
  }

  /*---- for showing cancel popup---*/
  cancelpopup(ride: any): void {
    const tripDetails = ride;
    this.rTrip1 = tripDetails;
    $('#cancelpopup').modal('show');
  }

  complete(ride: any): void {
    const tripDetails = ride;
    this.rTrip2 = tripDetails;
    $('#completepopup').modal('show');
  }

  createTicket(): void {
    $('#createTicket').modal('show');
  }

  viewTicket(): void {
    $('#viewTicket').modal('show');
    this.fetchTickets();
  }

  editTicket(ticket): void {
    $('#editTicket').modal('show');
    this.selectedTicket = ticket;
  }

  hideViewTicketPopup() {
    $('#viewTicket').modal('hide');
  }

  hideEditTicketPopup(): void {
    $('#editTicket').modal('hide');
  }

  hideDriverInfo(): void {
    $('#driverInfo').modal('hide');
  }

  hideDriverStatusPopup(): void {
    $('#driverStatus').modal('hide');
  }

  showDriverStatusPopup(): void {
    $('#driverStatus').modal('show');
  }

  hidestartpopup(): void {
    $('#startpopup').modal('hide');
  }

  showstartpopup(): void {
    $('#startpopup').modal('show');
  }

  hideArrivedPopup(): void {
    $('#arrivedpopup').modal('hide');
  }

  showArrivedPopup(): void {
    $('#arrivedpopup').modal('show');
  }

  hideRetryRide(): void {
    $('#confirmRetryRide').modal('hide');
  }

  hideCreateTicketpopup(): void {
    this.ticket = {
      type: '',
      status: '',
      description: ''
    };
    $('#createTicket').modal('hide');
  }

  /*---- for hiding cancel popup ----*/
  hidecancelpopup(): void {
    $('#cancelpopup').modal('hide');
  }
  /*---- for hiding complete popup ----*/
  hidecompletepopup(): void {
    $('#completepopup').modal('hide');
  }

  editRideTicket(): void {
    this.isTripDetailLoading = true;

    if (!this.selectedTicket.type) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Please select ticket type', 'red-snackbar');
      return;
    }

    if (!this.selectedTicket.status) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Please select ticket status', 'red-snackbar');
      return;
    }

    if (!this.selectedTicket.description) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Description is Mandatory ', 'red-snackbar');
      return;
    }

    const model = {
      access_token: this.token,
      type: this.selectedTicket.type,
      status: this.selectedTicket.status,
      description: this.selectedTicket.description,
      qudos_user_type: 0,
      qudos_user_id: this.selectedTicket.user_id,
      session_id: this.rideInFocus.session_id,
      ticket_id: this.selectedTicket.ticket_id
    }
    this.trackingService.editTicket(model)
      .subscribe((data) => {
        if (data.error || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.error, 'red-snackbar');
          return;
        } else if (data.message || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.message, 'red-snackbar');
          return;
        }
        this.isTripDetailLoading = false;
        this.hideEditTicketPopup();
        this.toasterService.open(data.log, 'success-snackbar');
      }, (err) => {
        this.isTripDetailLoading = false;
        this.toasterService.open('Something went wrong, Please try again', 'red-snackbar');
        return;
      })
  }

  createRideTicket(): void {
    this.isTripDetailLoading = true;

    if (!this.ticket.type) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Please select ticket type', 'red-snackbar');
      return;
    }

    if (!this.ticket.status) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Please select ticket status', 'red-snackbar');
      return;
    }

    if (!this.ticket.description) {
      this.isTripDetailLoading = false;
      this.toasterService.open('Description is Mandatory ', 'red-snackbar');
      return;
    }

    const model = {
      access_token: this.token,
      type: this.ticket.type,
      status: this.ticket.status,
      description: this.ticket.description,
      qudos_user_type: 0,
      qudos_user_id: this.user_id,
      session_id: this.rideInFocus.session_id
    }
    this.trackingService.createTicket(model)
      .subscribe((data) => {
        if (data.error || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.error, 'red-snackbar');
          return;
        } else if (data.message || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.message, 'red-snackbar');
          return;
        }
        this.isTripDetailLoading = false;
        this.hideCreateTicketpopup();
        this.toasterService.open(data.log, 'success-snackbar');
      }, (err) => {
        this.isTripDetailLoading = false;
        this.toasterService.open('Something went wrong, Please try again', 'red-snackbar');
        return;
      })
  }

  /*---- for complete ride----*/
  completeRide(): void {
    this.isTripDetailLoading = true;
    const model = {
      web_access_token: localStorage.getItem('web_access_token'),
      session_id: this.rTrip2.session_id
    };
    this.trackingService.complete(model)
      .subscribe((data) => {
        if (typeof (data) == 'string') {
          data = JSON.parse(data);
        }
        this.isTripDetailLoading = false;
        if (data.error || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.error, 'red-snackbar');
          return;
        } else if (data.message || data.flag == 808 || data.flag == 2015 || data.flag == 918 || data.flag == 915) {
          this.isTripDetailLoading = false;
          this.toasterService.open(data.message, 'red-snackbar');
          return;
        } else {
          this.isTripDetailLoading = false;
          this.isCompleted = true;
          this.toasterService.open(data.log, 'success-snackbar');
          $('#completepopup').modal('hide');
          this.clearMarkers();
          this.markers = [];
          this.refreshRidesData();
          this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, busyType: this.busyType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
        }
      });
  }

  /*---- for cancel ride----*/
  cancelRide(): void {
    const model = {
      web_access_token: localStorage.getItem('web_access_token'),
      session_id: this.rTrip1.session_id
    };

    if (!model.session_id || !model.web_access_token) {
      this.toasterService.open('Something went wrong', 'red-snackbar');
      return;
    }

    this.isTripDetailLoading = true;
    this.trackingService.cancel(model)
      .subscribe((data) => {
        if (typeof (data) == 'string') {
          data = JSON.parse(data);
        }
        if (data.error || data.flag == 0 || data.flag == 917) {
          alert(data.error || data.message);
          this.isTripDetailLoading = false;
          return;
        } else {
          $('#cancelpopup').modal('hide');
          this.toasterService.open(data.log, 'success-snackbar');
          this.clearMarkers();
          this.markers = [];
          this.isTripDetailLoading = false;
          this.refreshRidesData();
          this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, busyType: this.busyType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
        }
      });
  }

  searchDriver(): void {
    if (!this.searchdriver || (this.searchdriver && this.searchdriver.length < 2)) {
      return;
    }
    this.clearMarkers();
    this.markers = [];
    this.skip1 = 0;

    this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', sort_by: 'last_login', allType: 1, searchFlag: 1, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
    const model = {
      web_access_token: localStorage.getItem('web_access_token'),
      driver_name: this.searchdriver
    };

    this.trackingService.getDriver(model)
      .subscribe((data) => {
        if (typeof (data) == 'string') {
          data = JSON.parse(data);
        }
        this.searchedDriverList = data;
        if (data && data.length) {
          this.currentdriver = data[0];
          const latLng = new google.maps.LatLng(this.currentdriver.current_location_latitude, this.currentdriver.current_location_longitude); // Makes a latlng
          this.map.panTo(latLng);
          this.map.setZoom(16);
        }
      });
  }

  nextSearchedDriver(): void {
    const index = this.searchedDriverList.findIndex((driverData) => driverData === this.currentdriver)
    if (index === this.searchedDriverList.length - 1) {
      this.currentdriver = this.searchedDriverList[0];
    } else {
      this.currentdriver = this.searchedDriverList[index + 1];
    }
    const latLng = new google.maps.LatLng(this.currentdriver.current_location_latitude, this.currentdriver.current_location_longitude); // Makes a latlng
    this.map.panTo(latLng);
    this.map.setZoom(16);
  }

  previousSearchedDriver(): void {
    const index = this.searchedDriverList.findIndex((driverData) => driverData === this.currentdriver)
    if (index === 0) {
      this.currentdriver = this.searchedDriverList[this.searchedDriverList.length - 1];
    } else {
      this.currentdriver = this.searchedDriverList[index - 1];
    }
    const latLng = new google.maps.LatLng(this.currentdriver.current_location_latitude, this.currentdriver.current_location_longitude); // Makes a latlng
    this.map.panTo(latLng);
    this.map.setZoom(16);
  }

  previousDriverList(): void {
    this.clearMarkers();
    this.markers = [];
    this.page = this.page - 1;
    this.skip1 = (this.page - 1) * this.limit;
    this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', sort_by: 'last_login', onlineType: this.onlineType, busyType: this.busyType, offlineType: this.offlineType, logoutType: this.logoutType, allType: this.allType, searchFlag: 1, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
  }

  nextDriverList(): void {
    this.clearMarkers();
    this.markers = [];
    this.page = this.page + 1;
    this.skip1 = (this.page - 1) * this.limit;
    this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', sort_by: 'last_login', onlineType: this.onlineType, busyType: this.busyType, offlineType: this.offlineType, logoutType: this.logoutType, allType: this.allType, searchFlag: 1, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
  }

  searchLocation(): void {
    const input = document.getElementById('searchLocation');
    const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.panTo(place.geometry.location);
        this.map.setZoom(17);  // Why 17? Because it looks good.
      }
    });
  }

  ngOnInit(): void {
    this.globalEventService.changeCountrySubject.subscribe(receiveddata => {
      const latLng = new google.maps.LatLng(receiveddata.latitude, receiveddata.longitude); // Makes a latlng
      this.map.panTo(latLng);
      this.map.setZoom(receiveddata.zoom_level);
    });

    $('.showname').click(() => {
      clearTimeout(this.drivercount);
      if (this.toggle == false) {
        $('.gm-style-iw .gm-style-iw-c, .gm-style-iw-t').hide();
      } else if (this.toggle == true) {
        $('.gm-style-iw .gm-style-iw-c, .gm-style-iw-t').show();
      }
    });

    this.userDetails = {
      userName: this.UserModel.driver_name,
      driver_image: this.UserModel.driver_image,
      driver_mobile: this.UserModel.driver_mobile,
      driver_location: 'New York',
      referral_code: this.UserModel.referral_code,
      corporate_id: this.UserModel.corporate_id,
      live_user_type: this.UserModel.live_user_type
    };

    this.directionsDisplay = new google.maps.DirectionsRenderer();
    this.directionsService = new google.maps.DirectionsService();
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 40.732210, lng: -73.919020 },
      zoom: 11,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeControl: false,
      zoomControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      fullscreenControl: false,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      }
    });
    this.socketioService.emit('auth', { session_id: 0, user_type: 'livemap', access_token: this.token });
    this.init();
  }

  move(driverMarker: any, wait: any): void {
    if (driverMarker.path.length > 0) {
      driverMarker.isMoving = true;
      driverMarker.marker.setPosition(driverMarker.path[0][0]);
      const url = driverMarker.marker.getIcon().url;
      const markerImg: any = document.querySelector(`img[src="${url}"]`);
      if (markerImg) {
        const deg = driverMarker.path[0][1];
        markerImg.style.transform = 'rotate(' + deg + 'deg)';
      }
      driverMarker.path.splice(0, 1);
      setTimeout(() => {
        this.move(driverMarker, wait);
      }, wait * 10);
      const icon = driverMarker.marker.getIcon();
      driverMarker.marker.setIcon(icon);
    } else {
      driverMarker.isMoving = false;
    }
  }

  getDirections(): void {

    const request = {
      origin: this.strtloca,
      destination: this.mdes,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };


    this.directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsDisplay.setMap(this.map);
        this.directionsDisplay.setDirections(response);
      } else {
        console.log("MAP DIRECTION SERVICE STATUS => ", status);
        console.log("status", response);
        this.directionsDisplay.setMap(null);
      }
    });

  }

  timeConvert(num): string {
    const hours = (num / 60),
     rhours = Math.floor(hours),
     minutes = (hours - rhours) * 60,
     rminutes = Math.round(minutes);
    return rhours + " hrs " + rminutes + " mins";
  }

  expandDisc(daa: any, i: any, firstLoad = false, skipReload: boolean = false): void {

    console.log(daa);
    this.isTripDetailLoading = !skipReload;
    this.activeDriver = null;
    this.rideInFocusIndex = i;

    this.rideInFocus = daa;

    if(this.rideInFocus?.is_assigned_trip && this.rideInFocus?.ride_milestone > 0 && this.rideInFocus?.ride_milestone !== 5) {
        this.rideInFocus.next_milestone = this.rideInFocus?.ride_milestone + 1;
        this.rideInFocus.next_milestone_text = MileStone_Map[this.rideInFocus?.next_milestone];
        this.rideInFocus.is_live_milestone_trip = this.rideInFocus?.ride_milestone <= 4;
        this.rideInFocus.ride_milestone_class = MileStone_Class[this.rideInFocus?.next_milestone];
    }

    if (daa) {
      this.is_schedule = daa.is_schedule;
    }
    if (firstLoad) {
      this.firstClick = false;
      this.showRoute(daa);
    }
    if (daa) {
        /*-----Driver event----*/
        this.socketioService.emit('liveMapRequestedDriverList', { session_id: daa.session_id, is_schedule: this.is_schedule });
        this.socketioService.once('liveMapRequestedDriverList')
          .subscribe((data) => {
            if (!this.isTripDetailLoading) {
              this.isTripDetailLoading = true;
            }
            this.requestdata = data[0].data.paginated_list;
            if (this.requestdata[0]) {
              if (this.requestdata[0].session_id !== daa.session_id) {
                this.requestdata = [];
                this.isTripDetailLoading = false;
                return;
              }
              this.reqdate = this.requestdata[0].payment_date;
              this.acptdate = this.requestdata[0].schedule_calender_accept_time;
              const datestring = new Date(this.reqdate).toTimeString();
              const datestringtz = datestring.match(/\((.*)\)/);
              this.datestringtz = this.requestdata[0].offset;
              if (!this.driver_id && this.requestdata[0].id) {
                this.driver_id = Number(this.requestdata[0].id.split(',').pop());
              }

              this.is_schedule = this.requestdata[0].is_schedule;
              if (this.requestdata[0].is_schedule == 0) {
                this.pickdate = this.requestdata[0].start_time;
              } else {
                this.pick_up = moment(new Date(this.requestdata[0].pickup_time)).utc().format('MM/DD/YYYY');
                this.pick_up_time = moment(new Date(this.requestdata[0].pickup_time)).utc().format('hh:mm a');
              }

              for (i = 0; i < this.requestdata.length; i++) {
                this.requestedda = this.requestdata[i];
                this.requestdata[i].requestdataarray = this.requestedda.names ? this.requestedda.names.split('||') : new Array(0);
                this.requestdata[i].accepted_Driver_name = this.requestedda.driver_name;
                this.requestdata[i].business_name = this.requestedda.business_name;
                this.requestdata[i].sent_at = this.requestedda.sent_at;
                this.requestdata[i].offset = this.requestedda.offset;
                this.acptdriver = this.requestdata[i].accepted_Driver_name;
              }
            }
            this.isTripDetailLoading = false;
          });

        if (daa.pickup_location_address) {
          this.pickup_location_address = daa.pickup_location_address;
        } else if (daa.address) {
          this.pickup_location_address = daa.pickup_location_address;
        }
        this.accept_location_address = daa.accept_location_address;
        this.arrival_time = daa.arrival_time;
        this.drop_time = daa.drop_time;
        this.start_location = daa.start_location;
        this.arrive_location = daa.arrive_location;
        this.start_time = daa.start_time;
        this.pickup_time = daa.pickup_time;
        this.manual_destination_address = daa.manual_destination_address;
        this.drop_address = daa.drop_address;
        this.driver_name = daa.driver_name;
        this.driver_id = daa.driver_id;
        this.ride_essential = daa.ride_essential;
        this.car_name = daa.car_name;
        this.car_no = daa.car_no;
        this.estimate_pickup_time = daa.estimate_pickup_time;
        this.accept_time = daa.accept_time;
        this.estimate_diff = daa.estimate_diff;
        this.car_color = daa.car_color;
        this.total_with_tax = daa.total_with_tax;
        this.car_model = daa.car_model;
        this.car_make = daa.car_make;
        this.car_year = daa.car_year;
        this.ride_status = daa.ride_status;
        this.request_status = daa.request_status;
        this.is_active = daa.is_active;
        this.driver_mobile = daa.driver_mobile;
        this.business_name = daa.business_name;
        this.car_base_mobile = daa.car_base_mobile;
        this.booked_by_corporate = daa.booked_by_corporate;
        this.corporate_name = daa.corporate_name;
        this.corporate_mobile = daa.corporate_mobile;
        this.ticket_count = daa.ticket_count;

        if (daa && daa.date) {
          this.timerdate = daa.date;
          this.setTimer(this.timerdate);
        }

        if (daa.hasOwnProperty('session_id')) {
          this.tripnumber = 'Trip:#' + daa.session_id;
        }
        if (daa.hasOwnProperty('session_id') == false) {
          this.tripnumber = 'Trip:Unavailable';
        }
        this.user_name = daa.user_name;
        this.user_id = daa.user_id;
        this.user_mobile = daa.user_mobile;
        this.user_email = daa.user_email;
        this.user_image = daa.user_image;
        this.driver_image = daa.driver_image;
        this.driver_email = daa.driver_email;
        this.driver_image = daa.driver_image;

        if (daa.driver_image == null) {
          this.driver_image = 'http://qudos-s3.s3.amazonaws.com/user_profile/user.png';
        }

        this.ride_status_text = this.getButtonText(daa.ride_status, daa.request_status, daa.is_active);
    }
  }

  confirm_milestone_config : {
    mas_trip_id?: number,
    milestone?: number,
    heading?: string,
    description?: string,
    loading?: boolean
  }
  confirm_milestone_update (mas_trip_id: number, milestone: number) {
    this.confirm_milestone_config = {
      heading: MileStone_Map[milestone],
      description: Milestone_Update_Confirmation[milestone],
      mas_trip_id,
      milestone,
      loading: false
    };
    $('#confirmMilestoneUpdate').modal('show');
  }

  update_mas_assign_milestone () {
    this.confirm_milestone_config.loading = true;
    this.masAssignService.update_assigned_trip_milestone( {
      access_token: this.token,
      mas_trip_id: this.confirm_milestone_config?.mas_trip_id,
      milestone: this.confirm_milestone_config?.milestone,
      date: moment().toISOString()
    }).subscribe((res: {flag: number, message: string, log?: string, error?: string}) => {
      if(res?.flag === 923) {
        $('#confirmMilestoneUpdate').modal('hide');

        
        // if(this.confirm_milestone_config.milestone !== 6 && this.confirm_milestone_config?.milestone !== 5) {
        //   this.expandDisc(this.rideInFocus, this.rideInFocusIndex, this.firstClick, true);
        // }

        this.expandDisc(this.rideInFocus, this.rideInFocusIndex, this.firstClick, true);


        this.confirm_milestone_config = {};
        this.toasterService.open(res?.message || res?.log, 'success-snackbar');
      } else {
        this.confirm_milestone_config.loading = false;
        this.toasterService.open(res?.error || res?.message || res?.log || 'Something went wrong', 'red-snackbar');
      }
    }, err => {
      this.toasterService.open(err?.message || 'Something went wrong', 'red-snackbar');
      $('#confirmMilestoneUpdate').modal('hide');
    })
  }

  paginate(event: any): void {
    this.isTripDetailLoading = true;
    this.skip = event.pageIndex * event.pageSize;
    this.refreshRidesData();
  }

  searchL(): void {
    if (this.userDetails.live_user_type != '2') {
      this.isTripDetailLoading = true;
      this.refreshRidesData();
    }
  }

  setoffset(tabIndex: any): void {
    this.retryRide = false;
    this.setSubFilters(false, false, false, false, false, false);
    this.selectedIndex = tabIndex;
    if (tabIndex.index === 1) {
      let iPad = window.matchMedia("(max-width: 1024px)");
      if (iPad.matches) {
        $('.tabGroup .mat-tab-header .mat-tab-label-container .mat-tab-list').css('transform', 'translateX(-164px)');
      }
    }
    this.isTripListLoading = true;
    this.query.page = 1;
    this.skip = 0;
    this.firstClick = true;
    this.refreshRidesData();
  }

  countryChange(val: any, values: any): void {
    if (values) {
      this.globalEventService.changeCountry(values);
    }
  }

  getRideDetails(tripId): void {
    this.trackingService.getRides({
      trip_id: tripId,
      web_access_token:localStorage.getItem('web_access_token')
    })
    .subscribe(response => {
      this.rideDetails.drop_time = response.rideInfo[0].drop_time;
      this.rideDetails.vehicle = response.rideInfo[0].vehicle;
      this.rideDetails.user_email = response.rideInfo[0].user_email;
      this.showRideDetails = true;
    })
  }

  public getButtonText(index, req, is_active): string {
    switch (index) {
      case 0:
        if (req == 1) {
          return "Accepted";
        } else if (req == 10) {
          //return "Lapsed";
          return "Missed";
        } else if (req == null && is_active == 0) {
          return "Missed";
        } else {
          return "Assigning";
        }
      case 1:
        return "Picking Up";
      case 2:
        return "Arrived";
      case 3:
        return "En Route";
      case 4:
        return "Completed";
      case 8:
        return "Unsuccessful";
      case 5:
      case 6:
      case 7:
      case 9:
      case 11:
        return "Cancelled";
      default:
        return index + ' ' + req;
    }
  }

  public getButtonClass(index, req, is_active): string {
    switch (index) {
      case 0:
        if (req == 1) {
          return "green";
        } else if (req == 10) {
          return "black";
        } else if (req == null && is_active == 0) {
          return "black";
        } else {
          return "blue";
        }
      case 1:
      case 2:
      case 3:
      case 4:
        return "blue";
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 11:
        return "black";
      default:
        return index + ' ' + req;

    }
  }

  initFullscreenControl(): void {
    const elementToSendFullscreen = this.map.getDiv().firstChild as HTMLElement;
    const fullscreenControl = document.querySelector(
      ".fullscreen-control"
    ) as HTMLElement;
    var self = this;
    fullscreenControl.onclick = function () {
      if (self.isFullscreen(elementToSendFullscreen)) {
        self.exitFullscreen();
      } else {
        self.requestFullscreen(elementToSendFullscreen);
      }
    };

    document['onwebkitfullscreenchange'] = document['onmsfullscreenchange'] = document['onmozfullscreenchange'] = document.onfullscreenchange = function () {
      if (self.isFullscreen(elementToSendFullscreen)) {
        fullscreenControl.classList.add("is-fullscreen");
      } else {
        fullscreenControl.classList.remove("is-fullscreen");
      }
    };
  }

  setZoom(zoomIn: boolean, zoomOut: boolean): void {
    let level = this.map.getZoom()
    if (zoomIn) {
      level = level + 1
    }
    if (zoomOut) {
      level = level - 1
    }
    this.map.setZoom(level);
  }

  cropName(name: string, charSize: number): string {
    if (name && name.length > charSize) {
      return name.substring(0, charSize) + '...'
    } else {
      return name
    }
  }

  ngAfterViewInit(): void {
    // Create the DIV to hold the control and call the constructor passing in this DIV
    var geolocationDiv = document.createElement('div');
    this.GeolocationControl(geolocationDiv, this.map);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(geolocationDiv);
  }

  startRide(): void {
    const data = {
      access_token: this.token,
      session_id: this.rideInFocus.session_id
    }
    this.isTripDetailLoading = true;
    this.trackingService.startRide(data)
      .subscribe(resp => {
        if (resp.error) {
          this.isTripDetailLoading = false;
          this.toasterService.open(resp.error, 'red-snackbar');
        } else {
          this.isTripDetailLoading = false;
          this.isStarted = true;
          this.refreshRidesData();
          this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, busyType: this.busyType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
          this.toasterService.open('Trip started successfully', 'success-snackbar');
        }
      }, err => {
        this.isTripDetailLoading = false;
      })
  }

  fetchTickets(): void {
    const data = {
      access_token: this.token,
      session_id: this.rideInFocus.session_id
    }
    this.isTripDetailLoading = true;
    this.trackingService.fetchTickets(data)
      .subscribe(resp => {
        if (resp.error) {
          this.isTripDetailLoading = false;
          this.toasterService.open(resp.error, 'red-snackbar');
        } else {
          this.isTripDetailLoading = false;
          this.tickets = resp.tickets;
        }
      }, err => {
        this.isTripDetailLoading = false;
      })
  }

  setRetryRide(): void {
    this.consentChecked = false;
    this.retryRide = !this.retryRide
  }

  resetRetryRide(): void {
    this.activeRideType = this.activeRideEnum[this.selectedIndex];
    this.consentChecked = false;
    this.retryRide = false;
    this.directionsDisplay.setMap(null);
  }

  confirmRetryRide(): void {
    if (!this.consentChecked) {
      $('#confirmRetryRide').modal('show');
    } else {
      this.retryRideCall()
    }
  }

  consentChange(event): void {
    this.consentChecked = event.checked;
  }

  retryRideCall(): void {
    const data = {
      access_token: this.token,
      session_id: this.rideInFocus.session_id
    }
    this.isTripDetailLoading = true;
    this.trackingService.retryRide(data)
      .subscribe(resp => {
        if (resp.error) {
          this.isTripDetailLoading = false;
          this.toasterService.open(resp.error, 'red-snackbar');
        } else {
          this.isTripDetailLoading = false;
          this.retryRide = false;
          this.consentChecked = false;
          this.hideRetryRide();
          this.refreshRidesData();
          this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, busyType: this.busyType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
          this.toasterService.open('Trip Retarted successfully', 'success-snackbar');
        }
      }, err => {
        this.isTripDetailLoading = false;
      })
  }

  arrivedRide(): void {
    let data = {
      access_token: this.token,
      session_id: this.rideInFocus.session_id
    }
    this.isTripDetailLoading = true;
    this.trackingService.arrivedRide(data)
      .subscribe(resp => {
        if (resp.error) {
          this.isTripDetailLoading = false;
          this.toasterService.open(resp.error, 'red-snackbar');
        } else {
          this.isTripDetailLoading = false;
          this.refreshRidesData();
          this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, busyType: this.busyType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
          this.toasterService.open('Trip started successfully', 'success-snackbar');
        }
      }, err => {
        this.isTripDetailLoading = false;
      })
  }

  applyDriverStatus(): void {
    this.isTripDetailLoading = true;
    this.clearMarkers();
    this.markers = [];
    if (this.searchdriver) {
      this.searchFlagdriver = 1;
    } else if (!this.searchdriver) {
      this.searchFlagdriver = 0;
    }
    this.allType = this.driverStatus.allType;
    this.busyType = this.driverStatus.busyType;
    this.logoutType = this.driverStatus.logoutType;
    this.onlineType = this.driverStatus.onlineType;
    this.offlineType = this.driverStatus.offlineType;
    localStorage.setItem('allType', this.allType.toString());
    localStorage.setItem('busyType', this.busyType.toString());
    localStorage.setItem('logoutType', this.logoutType.toString());
    localStorage.setItem('onlineType', this.onlineType.toString());
    localStorage.setItem('offlineType', this.offlineType.toString());
    this.hideDriverStatusPopup();
    this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, busyType: this.busyType, logoutType: this.logoutType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
  }

  private setRidesCount(data): void {
    this.totalItemsScheduled = data[0].rides_count;
    this.totalItemsOngoing = data[0].rides_count;
    this.totalItemsCompleted = data[0].rides_count;
    this.totalItemsRegular = data[0].rides_count;
    if (!this.totalItemsScheduled || !this.totalItemsOngoing
      || !this.totalItemsCompleted || !this.totalItemsRegular) {
      this.isTripDetailLoading = false;
    }
  }

  private setCompletedRides(rides): void {
    this.requestedrides = [];
    this.scheduledrides = [];
    this.completedrides = rides;
    this.isTripDetailLoading = false;
    for (let i = 0; i < this.completedrides.length; i++) {
      const datestring = new Date(this.completedrides[i].start_time).toTimeString();
      const datestringtz = datestring.match(/\((.*)\)/);
      this.completedrides[i].datestringtz = datestringtz[1];
    }
  }

  private setScheduledRides(rides): void {
    this.requestedrides = [];
    this.completedrides = [];
    const scheduledrides = rides;
    // if(this.initialView){
    //   this.expandDisc(scheduledrides[this.rideInFocusIndex], this.rideInFocusIndex, this.firstClick);
    //   this.initialView = false;
    // }
    this.scheduledrides = scheduledrides;
    for (let i = 0; i < this.scheduledrides.length; i++) {
      if (this.scheduledrides[i].pickup_time) {
        const datestring = new Date(this.scheduledrides[i].pickup_time).toTimeString();
        const datestringtz = datestring.match(/\((.*)\)/);
        this.scheduledrides[i].datestringtz = datestringtz[1];
      } else if (this.scheduledrides[i].start_time) {
        const datestring = new Date(this.scheduledrides[i].start_time).toTimeString();
        const datestringtz = datestring.match(/\((.*)\)/);
        this.scheduledrides[i].datestringtz = datestringtz[1];
      }
      this.newara = this.scheduledrides[i];
      this.bouncelength = this.newara.sent_to ? this.newara.sent_to.split(',') : '';
      this.scheduledrides[i].newlength = this.bouncelength.length ? this.bouncelength.length : '0';
      this.scheduledrides[i].pick_up = moment(new Date(scheduledrides[i].pickup_time)).utc().format('MM/DD/YYYY');
      this.scheduledrides[i].pick_up_time = moment(new Date(scheduledrides[i].pickup_time)).utc().format('hh:mm a');
      this.scheduledrides[i].start_up_time = moment(new Date(scheduledrides[i].start_time)).utc().format('hh:mm a');
      this.scheduledrides[i].start_up_date = moment(new Date(scheduledrides[i].start_time)).utc().format('MM/DD/YYYY');
    }
  }

  private setRegularRides(rides): void {
    this.scheduledrides = [];
    this.completedrides = [];
    this.requestedrides = rides;
    if(this.initialView || this.rideInFocus?.mas_trip_id){
      this.expandDisc(rides[this.rideInFocusIndex], this.rideInFocusIndex, this.firstClick, true);
      this.initialView = false;
    }
    for (let i = 0; i < this.requestedrides.length; i++) {
      this.newara = this.requestedrides[i];
      const datestring = new Date(this.requestedrides[i].utc_date).toTimeString();
      const datestringtz = datestring.match(/\((.*)\)/);
      this.requestedrides[i].datestringtz = datestringtz[1];
    }
  }

  private init(): void {
    this.socketioService.on('auth')
      .subscribe((data) => {
        this.selectedIndex = 2; // on-demand
        this.selectedIn = 0; // online
        if (data[0].flag == true) {
          /*------ TOTAL COUNTS STARTS for all users ------*/
          if (this.userDetails.live_user_type != '2') {
            this.refreshRidesData();
            this.socketioService.on('liveMapRidesCount').subscribe((data) => {
              if (data) {
                this.setRidesCount(data[0].data);
              }
            });
            /*----- RIDES DETAIL EVENT STARTS -----*/
            this.socketioService.on('liveMapRidesDetail').subscribe((data) => {
              if (data) {
                this.isCompleted = false;
                this.isStarted = false;
                this.isSubLoading = false;
                if (!data[0].data.paginated_rides.length && data[0].tabType === this.tabType) {
                  this.requestedrides = [];
                  this.completedrides = [];
                  this.scheduledrides = [];
                  this.isTripDetailLoading = false;
                  this.firstClick = false;
                  return
                }
                // COMPLETED RIDES
                if (this.selectedIndex === 0 && data[0].data.paginated_rides[0] && data[0].tabType === this.tabType && data[0].data.paginated_rides[0].ride_status === 4) {
                  this.setCompletedRides(data[0].data.paginated_rides);
                }
                // SCHEDULED RIDES
                if (this.selectedIndex === 1 && data[0].data.paginated_rides[0] && data[0].data.paginated_rides[0].ride_status !== 4 && data[0].tabType === this.tabType && data[0].data.paginated_rides[0].is_schedule) {
                  this.setScheduledRides(data[0].data.paginated_rides);
                }
                // REGULAR RIDES
                if (this.selectedIndex === 2 && data[0].data.paginated_rides[0] && data[0].data.paginated_rides[0].ride_status !== 4 && data[0].tabType === this.tabType && !data[0].data.paginated_rides[0].is_schedule) {
                  this.setRegularRides(data[0].data.paginated_rides)
                }
              }
              this.isTripListLoading = false;
            });
          }

          /*---- RIDES DETAIL EVENT ENDS -----*/
          /*------ DRIVERS DETAIL EVENT START for admin -------*/

          this.skip1 = (this.page - 1) * this.limit;
          if (this.userDetails.live_user_type != '2') {
            this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', sort_by: 'last_login', onlineType: this.onlineType, busyType: this.busyType, offlineType: this.offlineType, logoutType: this.logoutType, allType: this.allType, searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
            this.socketioService.on('liveMapDriverAvailablity').subscribe((data) => {
              
              this.setMapOnAll(this.map);
              clearTimeout(this.drivercount);
              this.skip1 = (this.page - 1) * this.limit;
              if (data[0].data) {
                this.datalen = data[0].data;
                this.all = data[0].data.drivers;
                if (this.maxSize !== data[0].data.total_drivers) {
                  this.markers.forEach((marker, index) => {
                    let isMarkerAvailable = this.all.find(driver => marker.driver_id === driver.driver_id)
                    if (!isMarkerAvailable) {
                      this.markers[index].marker.setMap(null);
                    }
                  })
                  this.markers = this.markers.filter(marker => this.all.find(driver =>marker.driver_id === driver.driver_id))
                  this.maxSize = data[0].data.total_drivers;
                }
                let url;
                const image = {
                  url, // image is 512 x 512
                  scaledSize: new google.maps.Size(33, 33),
                  anchor: new google.maps.Point(16.5, 16.5)
                };

                this.activeDriver = this.all.find(x => x.driver_email === this.driver_email);

                if (this.all) {
                  for (let i = 0; i < this.all.length; i++) {
                    if (this.all[i]?.is_free == 0 && this.all[i]?.is_available == 1 && this.all[i]?.is_online == 1) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_intransit.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
                      }
                    }

                    // AVAILABLE DRIVERS
                    if (this.all[i]?.is_available == 1 && this.all[i]?.is_online == 1 && this.all[i]?.is_free == 1) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_idle.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/3_White_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/3_White_Grande.svg';
                      }
                    }

                    // BUSY Driver
                    if ((this.all[i]?.is_available == 0 || this.all[i]?.is_online == 0) && (this.all[i]?.is_free == 0 || this.all[i]?.is_free == 1)) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/1_Grey_QLE.svg';
                      } else if (this.all[i].car_type == 1 || this.all[i].car_type == 5) {
                        url = 'assets/img/driver_offline.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/1_Grey_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/1_Grey_Grande.svg';
                      }
                    }
                    if (this.all[i]?.docs_expired == 1) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/4_RED_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_expiry.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/4_RED_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/4_RED_GRANDE.svg';
                      }
                    }

                    let content = '<div id=\'' + this.all[i].driver_id + '\' style=\'font-size: 9px; padding: 8px;\' class=\'infoWindow\'>' +
                    '<span></span>  <span>' + this.cropName(this.all[i].driver_name, 30) + '</span><br>' +
                    '</div>';

                      if (this.activeDriver && this.ride_status_text === 'Picking Up' || this.ride_status_text == 'En Route') {
                        let time = this.ride_status_text === 'Picking Up' ? this.rideInFocus.estimate_pickup_time : this.rideInFocus.estimate_diff;
                        if(!time || time == "NaN" ) time = '- ';
                        content =
                            "<div class='detail' id='" +
                            this.all[i].driver_id +
                            "' style='font-size: 9px;'>" +
                            "<div style='background: #1f8dc6; color: white' class='p-2'>" + (time || '-ß') + 'mins | ' +
                            this.ride_status_text +
                            "</div> <div class='p-1 mr-2'>" +
                            this.all[i].driver_name.slice(0, 20) +
                            '</div><br>' +
                            '</div>';
                        } else if (this.activeDriver && this.ride_status_text === 'Arrived') {
                            content =
                                "<div class='detail' id='" +
                                this.all[i].driver_id +
                                "' style='font-size: 9px;'>" +
                                "<div style='background: #1f8dc6; color: white' class='p-2'>" +
                                this.ride_status_text +
                                "</div> <div class='p-1 mr-2'>" +
                                this.all[i].driver_name.slice(0, 20) +
                                '</div><br>' +
                                '</div>';
                        }

                    const infowindow = new google.maps.InfoWindow({
                      content,
                      disableAutoPan: true,
                    });
                    image.url = `${url}#${this.all[i].driver_id}`;

                    this.setMarker(this.all[i], { image, infowindow, rotation: this.rotation });
                    if( this.activeDriver && this.all[i]?.driver_id !== this.activeDriver?.driver_id){
                      infowindow.close();
                    }
                  }
                }
                clearTimeout(this.drivercount);
                if (this.searchdriver) {
                  this.searchFlagdriver = 1;
                } else if (!this.searchdriver) {
                  this.searchFlagdriver = 0;
                }
                this.drivercount = setTimeout(() => {
                  this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, busyType: this.busyType, logoutType: this.logoutType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
                }, 3000);
              }
            });
          }

          /* -----Drivers Event for Viewers ----*/
          this.skip2 = (this.query1.page - 1) * this.query1.limit;
          if (this.userDetails.live_user_type == '2') {
            this.socketioService.emit('liveMapViewerTypeDrivers', { region_id: 0, limit: this.query1.limit, offset: this.skip2, requestType: this.selectedIn + 1, sort_order: 'ASC', sort_by: this.query1.order, searchFlag: this.searchFlag, searchString: this.searchString });
          }

          this.socketioService.on('liveMapViewerTypeDrivers').subscribe((data) => {
            this.skip2 = (this.query1.page - 1) * this.query1.limit;
            // Online Drivers
            if (data) {
              let onlinedrivers = [];
              if (this.selectedIn == 0) {
                clearTimeout(this.viewerdata);
                onlinedrivers = data[0].data.drivers;
                onlinedrivers = onlinedrivers;
              }
              // Offline Drivers
              if (this.selectedIn == 1) {
                onlinedrivers = [];
                clearTimeout(this.viewerdata);
                let offlinedrivers = data[0].data.drivers;
                offlinedrivers = offlinedrivers;
              }

              clearTimeout(this.viewerdata);
              this.viewerdata = setTimeout(() => {
                this.socketioService.emit('liveMapViewerTypeDrivers', { region_id: 0, limit: this.query1.limit, offset: this.skip2, requestType: this.selectedIn + 1, sort_order: 'ASC', sort_by: this.query1.order, searchFlag: this.searchFlag, searchString: this.searchString });
              }, 3000);
            }
          });

          this.skip1 = (this.page - 1) * this.limit;
          if (this.userDetails.live_user_type == '2') {
            this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', sort_by: 'last_login', busyType: this.busyType, onlineType: this.onlineType, offlineType: this.offlineType, logoutType: this.logoutType, allType: this.allType, searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
            this.socketioService.on('liveMapDriverAvailablity').subscribe((data) => {
              clearTimeout(this.drivercount);
              this.skip1 = (this.page - 1) * this.limit;
              if (data) {
                this.datalen = data[0].data;
                this.all = data[0].data.drivers;
                this.maxSize = this.datalen.total_drivers;
                let url;
                const image = {
                  url, // image is 512 x 512
                  scaledSize: new google.maps.Size(33, 33),
                  anchor: new google.maps.Point(16.5, 16.5)
                };
                if (this.all) {
                  // OFLINE DRIVER
                  for (let i = 0; i < this.all.length; i++) {
                    if (this.all[i].is_free == 0 && this.all[i].is_available == 1 && this.all[i].is_online == 1 && this.all[i].docs_expired == 0) {
                      if (this.all[i].car_type == 1) {
                        url = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_intransit.svg';
                      } else if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
                      }
                    }

                    // AVAILABLE DRIVERS
                    else if (this.all[i].is_available == 1 && this.all[i].is_online == 1 && this.all[i].docs_expired == 0) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_idle.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/3_White_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/3_White_Grande.svg';
                      }
                    }

                    // BUSY Driver
                    if ((this.all[i].is_available == 0|| this.all[i].is_online == 0) && this.all[i].docs_expired == 0) {
                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/1_Grey_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_offline.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/1_Grey_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/1_Grey_Grande.svg';
                      }
                    }
                    // Driver with expired docs
                    if (this.all[i].docs_expired == 1) {

                      if (this.all[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/4_RED_QLE.svg';
                      } else if (this.all[i].car_type == 1) {
                        url = 'assets/img/driver_expiry.svg';
                      } else if (this.all[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/4_RED_LUXE.svg';
                      } else if (this.all[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/4_RED_GRANDE.svg';
                      }
                    }

                    let content = '<div id=\'' + this.all[i].driver_id + '\' style=\'font-size: 9px; padding: 8px;\' class=\'infoWindow\'>' +
                    '<span></span>  <span>' + this.cropName(this.all[i].driver_name, 30) + '</span><br>' +
                    '</div>';

                      if (this.ride_status_text === 'Picking Up' || this.ride_status_text == 'En Route') {
                        const time = this.ride_status_text === 'Picking Up' ? this.rideInFocus.estimate_pickup_time : this.rideInFocus.estimate_diff;
                        content =
                            "<div class='detail' id='" +
                            this.all[i].driver_id +
                            "' style='font-size: 9px;'>" +
                            "<div style='background: #1f8dc6; color: white' class='p-2'>" +
                            time +
                            'mins | ' +
                            this.ride_status_text +
                            "</div> <div class='p-1 mr-2'>" +
                            this.all[i].driver_name.slice(0, 20) +
                            '</div><br>' +
                            '</div>';
                        } else if (this.ride_status_text === 'Arrived') {
                            content =
                                "<div class='detail' id='" +
                                this.all[i].driver_id +
                                "' style='font-size: 9px;'>" +
                                "<div style='background: #1f8dc6; color: white' class='p-2'>" +
                                this.ride_status_text +
                                "</div> <div class='p-1 mr-2'>" +
                                this.all[i].driver_name.slice(0, 20) +
                                '</div><br>' +
                                '</div>';
                        }

                    const infowindow = new google.maps.InfoWindow({
                      content,
                      disableAutoPan: true,
                    });
                    image.url = `${url}#${this.all[i].driver_id}`;
                    this.setMarker(this.all[i], { image, infowindow, rotation: this.rotation });
                    if( this.activeDriver && this.all[i]?.driver_id !== this.activeDriver?.driver_id){
                      infowindow.close();
                    }
                  }
                }
                clearTimeout(this.drivercount);
                if (this.searchdriver) {
                  this.searchFlagdriver = 1;
                } else if (!this.searchdriver) {
                  this.searchFlagdriver = 0;
                }
                this.drivercount = setTimeout(() => {
                  this.socketioService.emit('liveMapDriverAvailablity', { sort_order: 'DESC', onlineType: this.onlineType, offlineType: this.offlineType, busyType: this.busyType, logoutType: this.logoutType, allType: this.allType, sort_by: 'last_login', searchFlag: this.searchFlagdriver, searchString: this.searchdriver, limit: this.limit, offset: this.skip1 });
                }, 3000);
              }
            });
          }
        }
      });
  }

  private GeolocationControl(controlDiv, map): void {
    // Set CSS for the control button
    var controlUI = document.createElement('button');
    controlUI.style.backgroundColor = 'rgb(255, 255, 255)';
    controlUI.style.border = 'none';
    controlUI.style.outline = 'none';
    controlUI.style.width = '40px';
    controlUI.style.height = '40px';
    controlUI.style['margin-right'] = '9px';
    controlUI.style['border-radius'] = '2px';
    controlUI.style['box-shadow'] = 'rgba(0, 0, 0, 0.3) 0px 1px 4px';
    controlUI.style.cursor = 'pointer';
    controlDiv.appendChild(controlUI);
    // Set CSS for the control text
    var controlText = document.createElement('div');
    controlText.style.width = '18px';
    controlText.style.height = '18px';
    controlText.style.margin = '10px';
    controlText.style['background-repeat'] = 'no-repeat';
    controlText.style['background-position'] = '0px 0px';
    controlText.style['background-size'] = '180px 18px';
    controlText.style['background-image'] = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
    controlUI.appendChild(controlText);
    // Setup the click event listeners to geolocate user
    this.getLocation = this.getLocation.bind(this)
    google.maps.event.addDomListener(controlUI, 'click', this.getLocation);
  }

  private refreshRidesData(): void {
    clearTimeout(this.ridesDataTimer);
    this.socketioService.emit('liveMapRidesDetail', { region_id: 0, tabType: this.tabType, limit: this.query.limit, offset: this.skip, requestType: this.selectedIndex + 2, sort_order: 'DESC', sort_by: this.query.order, searchFlag: this.searchFlag, searchString: this.searchString });
    this.socketioService.emit('liveMapRidesCount', { requestType: this.selectedIndex + 1, action: 0, tabType: this.tabType, region_id: 0, searchFlag: this.searchFlag, searchString: this.searchString });
    this.ridesDataTimer = setTimeout(() => { this.refreshRidesData(); }, 5000);
  }

  private setMapOnAll(map: any): void {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].marker.setMap(map);
    }
  }

  private clearMarkers(): void {
    this.setMapOnAll(null);
  }

  private getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        this.map.setCenter(pos);
        this.map.setZoom(12);
      }, failure => {
        this.trackingService.geoLocation()
          .subscribe(data => {
            var pos = new google.maps.LatLng(data.location.lat, data.location.lng);
            this.map.setCenter(pos);
            this.map.setZoom(12);
          })
      });
    }
  }

  private isFullscreen(element: HTMLElement): boolean {
    return (
      (document.fullscreenElement ||
        document['webkitFullscreenElement'] ||
        document['mozFullScreenElement'] ||
        document['msFullscreenElement']) == element
    );
  }

  private requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element['webkitRequestFullScreen']) {
      element['webkitRequestFullScreen']();
    } else if (element['mozRequestFullScreen']) {
      element['mozRequestFullScreen']();
    } else if (element['msRequestFullScreen']) {
      element['msRequestFullScreen']();
    }
  }

  private exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  private showDriverInfo(): void {
    $('#driverInfo').modal('show');
  }

  private markerClicked(driverId): void {
    this.trackingService.getDriverRides({
      driver_id: driverId,
      web_access_token:localStorage.getItem('web_access_token')
    })
    .subscribe(response => {
      if (response && response.driverDetails && response.driverDetails.length) {
          this.dirverDetails = response.driverDetails[0];
      } else {
        this.dirverDetails = {
          type_seat: '',
          driver_info: '',
          vehicle: '',
          driver_id: '',
          car_color: '',
          driver_mobile: ''
        };
      }
      if (response && response.riderAndRideDetails && response.riderAndRideDetails.length){
        this.rideDetails = response.riderAndRideDetails[0];
      } else {
        this.rideDetails = {
          rider_info: '',
          pickup_real_time: '',
          pickup: '',
          drop_off: '',
          trip_id: '',
          request_status: '',
          is_active: '',
          ride_status: '',
          vehicle: '',
          drop_time: '',
          user_email: ''
        };
      }
      
      this.showRideDetails = false;
      this.showDriverInfo();
    })
  }

  private showRoute(data: any): void {
    if (data) {
      if (data.session_id) {
        this.strtloca = data.pickup_latitude + ',' + data.pickup_longitude;
      } else if (data.pickup_id) {
        this.strtloca = data.latitude + ',' + data.longitude;
      }
      this.mdes = data.manual_destination_latitude + ',' + data.manual_destination_longitude;
      this.getDirections();
    }
  }

  private addZero(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  private setTimer(date: any): void {
    const givendate = new Date(date);
    const givenmilliseconds = givendate.getTime();
    const nowdate = new Date();
    const nowmilliseconds = nowdate.getTime();
    let secondsSinceBooked = nowmilliseconds - givenmilliseconds;
    secondsSinceBooked = secondsSinceBooked / 1000;
    const lapsWindow = 135;
    let string = '';
    if (secondsSinceBooked <= lapsWindow) {
      const secsLeft = lapsWindow - secondsSinceBooked;
      const totalsecs = secsLeft;
      const mins: any = totalsecs / 60;
      let cleanMins: any = parseInt(mins);
      let secs = totalsecs % 60;
      const cleanSecs = Math.floor(secs);
      secs = this.addZero(cleanSecs);
      cleanMins = this.addZero(cleanMins);
      string = cleanMins + ':' + secs;
    } else {
      string = '00:00';
    }
    this.timer = string;
  }

  setMarker(driverData: any, { image, infowindow, rotation }, showMarker: boolean = true) {
    const self = this;
    const driverMarker = this.markers.find(marker => marker.driver_id == driverData.driver_id);
    const location = new google.maps.LatLng(driverData.current_location_latitude, driverData.current_location_longitude);
    let marker;
    if (driverMarker) {
      if (driverMarker.driver_id === this.driver_id) {
        driverMarker.marker.infoWindow.setContent(infowindow.content);
      } else {
        let content = '<div id=\'' + driverMarker.driver_id + '\' style=\'font-size: 9px; padding: 8px;\' class=\'infoWindow\'>' +
        '<span></span>  <span>' + this.cropName(driverMarker.driver_name, 30) + '</span><br>' +
        '</div>';
        driverMarker.marker.infoWindow.setContent(content);
      }
      new google.maps.event.trigger(driverMarker.marker, 'click');
      let prevPos = driverMarker.marker.getPosition();
      if (driverMarker.path.length > 0) {
        prevPos = driverMarker.path[driverMarker.path.length - 1][0];
      }
      const icon = driverMarker.marker.getIcon();
      icon.url = image.url;
      driverMarker.marker.setIcon(icon);
      const rotation = google.maps.geometry.spherical.computeHeading(prevPos, location);
      const fromLat = prevPos.lat();
      const fromLng = prevPos.lng();
      const toLat = location.lat();
      const toLng = location.lng();
      if (fromLat != toLat || fromLng != toLng) {
        let diff = Date.now() - driverMarker.time;
        driverMarker.time = Date.now();
        let frames = driverMarker.path;
        let hasPath = false;
        if (frames.length > 0) {
          hasPath = true;
        }
        if (diff > 2000) {
          diff = 1000;
        }
        if (frames.length >= 100) {
          frames = [];
        }

        for (let percent = 0; percent < 1; percent += 0.01) {
          const curLat = fromLat + percent * (toLat - fromLat);
          const curLng = fromLng + percent * (toLng - fromLng);
          frames.push([new google.maps.LatLng(curLat, curLng), rotation]);
        }
        driverMarker.path = frames;
        if (!hasPath) {
          this.move(driverMarker, diff / 100);
        } else if (!driverMarker.isMoving) {
          this.move(driverMarker, diff / 100);
        } else {
          this.move(driverMarker, 0.5);
        }
      }
      marker = driverMarker.marker;
    } else {
      marker = new google.maps.Marker({
        position: location,
        icon: image,
        map: this.map,
        infoWindow: infowindow
      });
      
      marker.addListener('click', function (e) {
        if(showMarker){
          marker.infoWindow.open(self.map, this);
          $('.gm-style-iw')?.next('div').hide();
          // contentDiv.next('div').hide();
        }
        
        setTimeout(() => {
          const contentDiv = $('.gm-style-iw');
          contentDiv.prev('div.custom-close').remove();
          const closeBtn =
            `<div class="custom-close" id="${driverData.driver_id}">
                      <img alt="" src="" draggable="false" style="position: absolute; left: -2px; top: -336px; width: 59px; height: 492px; user-select: none; border: 0px; padding: 0px; margin: 0px; max-width: none;">
                  </div>`;
          $(closeBtn).insertBefore(contentDiv);
          $('div.custom-close').bind('click', (e) => {
            $(e.target).parent().parent().css({ opacity: 0, 'pointer-events': 'none' });
          });
        });
      });

      this.markers.push({
        driver_id: driverData.driver_id,
        driver_name: driverData.driver_name,
        marker,
        time: Date.now(),
        path: [],
        isMoving: false
      });
    }

    $('.gm-style-iw').off('click').on('click', (e) => {
      e.preventDefault();
      if(e.currentTarget.children.length && e.currentTarget.children[0].children.length && e.currentTarget.children[0].children[0].children.length){
        self.markerClicked(e.currentTarget.children[0].children[0].children[0].id);
      }
    })
    return this.markers;
  }
}
