import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ErrorService } from './error.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl = environment.socketApiURL;
  constructor(private httpClient: HttpClient,
              private errorService: ErrorService) { }

  login(model: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_login`, model)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  logout(model: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_logout`, model)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

}
