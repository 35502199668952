import { Component, OnInit } from '@angular/core';

import { Message, Sender } from '../../models';
import { MessageService, ToasterService } from '../../services';
declare let $: any;

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  active_trip_tab: number = 1;
  activeMessage: boolean = false;
  activeMessageData = {};
  isLoading: boolean = true;
  email = '';
  sms = '';
  createmsg = {
    title: '',
    content: ''
  };
  disableresend: boolean = false;
  testform1 = {
    mobno: '',
    email: ''
  };

  Senders: Sender[] = [
    { name: 'Rider', value: "0", checked: false },
    { name: 'New Driver', value: "1", checked: false },
    { name: 'Verified Driver', value: "2", checked: false },
    { name: 'Approved Driver', value: "3", checked: false },
    { name: 'Affiliate', value: "4", checked: false },
    { name: 'Corporate', value: "5", checked: false }];

  selection = [];
  selectionid = [];
  savebutton: boolean = false;
  editbutton: boolean = false;
  testbutton: boolean = true;
  skip: number = 0;
  query = {
    filter: '',
    order: 'id',
    limit: 4,
    page: 1,
    requestType: 0
  };
  message: any;
  totallength: number = 0;
  selectedsent_type: any;
  selectedsent_id: any = '';
  editdata: Message = {
    id: '',
    title: '',
    description: '',
    send_via: '',
    sent_to: ''
  };
  cachedEditData: Message = {
    id: '',
    title: '',
    description: '',
    send_via: '',
    sent_to: ''
  };
  errormesg: string = '';
  sendvia: any;
  send_on: any;
  message_id: any;
  Duplicate_id: any;

  selectedCountryCode: string = 'us';
  sendTo: string = '';
  countryCodes: string[] = ['us', 'in'];
  testPopup: boolean = false;
  searchString: string = '';
  send_via: string = '';
  senderDetail: Sender = {
    name: '',
    value: '',
    checked: false
  };

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }

  onPaginateChange($event: any): void {
    this.query.page = $event.pageIndex;
    this.fetchmesssage();
  }

  fetchmesssage(): void {
    this.isLoading = true;
    if (!this.query.page) {
      this.query.page = 1;
    }
    this.skip = (this.query.page - 1) * this.query.limit;
    const data = {
      access_token: localStorage.getItem('web_access_token'),
      send_via: this.active_trip_tab.toString(),
      limit: this.query.limit,
      offset: this.skip
    };
    this.messageService.search(data)
      .subscribe((response: any) => {
        this.isLoading = false;
        const messagelist = response;
        this.message = messagelist.data;
        this.totallength = response.total_messages;
      });
  }

  search(): void {
    this.isLoading = true;
    if (!this.query.page) {
      this.query.page = 1;
    }
    this.skip = (this.query.page - 1) * this.query.limit;
    const data = {
      access_token: localStorage.getItem('web_access_token'),
      search_flag: 1,
      search_string: this.searchString
    };
    this.messageService.search(data)
      .subscribe((response: any) => {
        this.isLoading = false;
        const messagelist = response;
        this.message = messagelist.data;
        this.totallength = response.total_messages;
      });
  }

  checktype(event: any): void {
    this.selectedsent_type = event.target.value;
    if (this.selectedsent_type == 'sms') {
      this.selectedsent_id = 1;
    } else if (this.selectedsent_type == 'email') {
      this.selectedsent_id = 0;
    }
  }

  EditDialog = function (data: any) {
    let sent__to = [];
    this.editdata = data;
    this.cachedEditData = { ...data };

    sent__to = this.editdata.sent_to.split(',');
    this.Senders.forEach((sender: any) => {
      if (sender.checked) {
        sender.checked = false;
      }
    });
    for (let j = 0; j < sent__to.length; j++) {
      this.Senders[sent__to[j]].checked = true;
    }

    this.senderDetail = this.Senders.find((sender => sender.checked === true));
    $('#editdialog').modal('show');
  };

  senderChecked(event): void {
    this.Senders.forEach((sender: Sender) => {
      if (sender.checked) {
        sender.checked = false;
      }
    });
    this.Senders[event.target.value].checked = true;
  }

  setSenderDetail(event): void {
    this.Senders.forEach((sender: any) => {
      if (sender.checked) {
        sender.checked = false;
      }
    });
    this.Senders[event.target.value].checked = true;
    this.senderDetail = this.Senders[event.target.value];
  }

  edittedmsg(): boolean {
    if (this.editdata.title == '') {
      this.toasterService.open('Title is Mandatory ', 'red-snackbar');
      return false;
    }

    if (this.editdata.description == '') {
      this.toasterService.open('Content is Mandatory ', 'red-snackbar');
      return false;
    }

    this.selection = [];
    this.selectionid = [];
    this.Senders.forEach(sender => {
      if (sender.checked) {
        this.selection.push(sender.name);
        this.selectionid.push(sender.value);
        this.errormesg = '';
      }
    });
    this.editbutton = true;
    const data = {
      access_token: localStorage.getItem('web_access_token'),
      message_id: this.editdata.id,
      title: this.editdata.title,
      description: this.editdata.description,
      send_via: this.editdata.send_via, // 0=email,1=sms,
      sender_types: this.selection.toString(),
      send_to: this.selectionid.toString()
    };

    this.messageService.update(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') { response = JSON.parse(response); }
        if (response.flag == 2032) {
          $('#editdialog').modal('hide');
          this.toasterService.open(response.log, 'success-snackbar');
          this.fetchmesssage();
          this.editbutton = false;
        } else {
          this.toasterService.open(response.error, 'red-snackbar');
          this.editbutton = false;
        }
      });
  }

  savemessage(): boolean {
    this.errormesg = '';
    this.selection = [];
    this.selectionid = [];
    if (this.createmsg.title == '' || this.createmsg.content == '') {
      return false;
    }
    this.sendvia = this.selectedsent_id;
    if (this.sendvia == undefined || this.sendvia === '') {
      this.toasterService.open('Please Select Sent Type', 'red-snackbar');
      return false;
    }
    this.Senders.forEach(sender => {
      if (sender.checked) {
        this.errormesg = '';
        this.selection.push(sender.name);
        this.selectionid.push(sender.value);
      }
    });

    if (!this.selection.length) {
      this.toasterService.open('Please Select Sent To', 'red-snackbar');
      return false;
    }

    this.savebutton = true;
    const data = {
      title: this.createmsg.title,
      description: this.createmsg.content,
      access_token: localStorage.getItem('web_access_token'),
      send_via: this.selectedsent_id.toString(), // 0=email,1=sms,
      sender_types: this.selection.toString(),
      send_to: this.selectionid.toString()
    };
    this.messageService.searchNew(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') { response = JSON.parse(response); }
        if (response.flag == 2031) {
          $('#createmessage').modal('hide');
          this.toasterService.open(response.log, 'success-snackbar');
          this.fetchmesssage();
          this.savebutton = false;
        } else {
          this.toasterService.open(response.error, 'red-snackbar');
          this.savebutton = false;
        }
      });
  }

  createnewmsg(): void {
    $('#createmessage').modal('show');
    this.createmsg.title = '';
    this.createmsg.content = '';
    this.selectedsent_id = '';
    this.sendTo = '';
    this.sms = '';
    this.email = '';
    this.testPopup = false;
    this.Senders.forEach((sender: any) => {
      if (sender.checked) {
        sender.checked = false;
      }
    });
  }

  DeleteDialog(data: any): void {
    this.message_id = data.id;
    this.send_via = data.send_via;
    $('#deletemessage').modal('show');
  }

  phonepopup(): boolean {
    this.testPopup = true;
    this.sendvia = this.selectedsent_id;
    this.testform1 = {
      mobno: '',
      email: ''
    };
    if (this.sendvia == undefined) {
      this.toasterService.open('Please Select Sent Type', 'red-snackbar');
      return false;
    } else {
      $('#phonetest').modal('show');
    }
  }

  duplicatedialog = (id: any) => {
    this.Duplicate_id = id;
    $('#duplicatedialog').modal('show');
  }
  // DUplicate Message popup
  Duplicate = () => {
    const data = {
      message_id: this.Duplicate_id,
      access_token: localStorage.getItem('web_access_token')
    };

    this.messageService.duplicateMsg(data)
      .subscribe((response: any) => {
        this.toasterService.open(response.error, 'red-snackbar');
        if (typeof (response) == 'string') { response = JSON.parse(response); }
        if (response.flag == 2034) {
          this.fetchmesssage();
          $('#duplicatedialog').modal('hide');
          this.toasterService.open(response.log, 'success-snackbar');
        } else {
          this.toasterService.open(response.error, 'red-snackbar');
        }
      });
  }
  // Delete message
  DeleteMessage(): void {
    const data = {
      message_id: this.message_id,
      access_token: localStorage.getItem('web_access_token')
    };
    this.messageService.delete(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') { response = JSON.parse(response); }
        if (response.flag == 2033) {
          this.toasterService.open(response.log, 'success-snackbar');
          this.fetchmesssage();
          $('#deletemessage').modal('hide');
        } else {
          this.toasterService.open(response.error, 'red-snackbar');
        }
      });
  }

  validateEmail(event): void {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    this.testbutton = false;
    if (reg.test(event.target.value) == false) {
      this.testbutton = true;
      return;
    }

    return;
  }

  validateMobile(event): void {
    var reg = /^([1-9]{1}[0-9]{9})$/;
    this.testbutton = false;
    event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
    if (reg.test(event.target.value) == false) {
      this.testbutton = true;
      return;
    }

    return;
  }

  test(testform: any, selectedsent_id: any): boolean {

    if (!this.testform1.mobno && !this.testform1.email) {
      this.toasterService.open('Please fill the required details', 'red-snackbar');
      return false;
    }

    if (testform) {
      this.testbutton = true;
    }
    if (selectedsent_id == 1) {

      let countrycode = '+91-';
      switch (this.selectedCountryCode) {
        case "in":
          countrycode = '+91-';
          break;
        case "us":
          countrycode = '+1-';
        default:
          countrycode = '+91-';
      }
      this.send_on = countrycode + this.testform1.mobno;
    }

    const data = {
      title: this.createmsg.title,
      description: this.createmsg.content,
      send_on: this.send_on ? this.send_on : this.testform1.email,
      send_via: this.selectedsent_id, // 0=email,1=sms,
      access_token: localStorage.getItem('web_access_token')
    };

    this.messageService.test(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.error) {
          this.toasterService.open(response.error, 'red-snackbar');
          this.testbutton = false;
        } else {
          $('#phonetest').modal('hide');
          $('#createmessage').modal('hide');
          this.toasterService.open(response.log, 'success-snackbar');
          this.fetchmesssage();
          this.createmsg = {
            title: '',
            content: ''
          };
          this.testbutton = false;
          this.testPopup = false;
        }
      });
  }

  sendmessage = (msg: any) => {
    this.disableresend = true;
    const data = {
      access_token: localStorage.getItem('web_access_token'),
      message_id: msg.id
    };

    this.messageService.send(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.flag == 3100) {
          this.disableresend = false;
          this.toasterService.open(response.message, 'success-snackbar-width');
          this.fetchmesssage();
        } else {
          this.disableresend = false;
          this.toasterService.open(response.error, 'red-snackbar');
        }
      });
  }

  convertToCSV(objArray, headerList): string {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadFile(data, filename = 'data'): void {
    let csvData = this.convertToCSV(data, ['title', 'description', 'send_medium', 'sender_types']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  constructor(private messageService: MessageService,
    private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.fetchmesssage();
  }

}
