import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class RedirectInterceptorService implements HttpInterceptor {

  constructor(protected router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((response: any) => {
      if (response && response.body && response.body.flag == 101) {
        this.router.navigate(['login']);
      }
    }));
  }
}
