import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private httpClient: HttpClient) { }

  handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      return throwError('Network Problem');
    }
    if (!error.error) {
      console.log('crosscheck this msg', error);
      return;
    }
    return throwError(`${error.error.error}`);
  }

  setHeaders = () => {
    return new HttpHeaders().append(
      'x-access-token',
      localStorage.getItem('token')
    );
  }

  setQuery = (filters: any) => {
    return Object.getOwnPropertyNames(filters)
      .reduce((p, key) => p.append(key, filters[key]), new HttpParams());
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(imageUrl, { observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        return new Blob([res.body], { type: res.headers.get('Content-Type') });
      }));
  }
}
