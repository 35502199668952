<div class="navbar navbar-expand-lg fixed-top header">

  <div class="container-fluid">

    <div id="mySidenav" class="sidenav">
      <div class="slide" id="slide">
        <span class="myprofile"><i class="fa fa-user-circle myprofileuser" aria-hidden="true"></i>&nbsp;&nbsp; My
          Profile
          <a (click)="closeNav()" class="rclosebtn">&nbsp;<i class="fa fa-times"></i></a></span>
        <hr class="myprofilehr">

        <img *ngIf="userDetails.business_image" src="{{userDetails?.business_image}}" id="dprofileimageone"
          name="imageone">
        <img *ngIf="!userDetails.business_image" src="{{userDetails?.profile_image}}" id="dprofileimageone"
          name="imageone">
        <p class="name">{{userDetails.driver_name}} <br><span style="font-size:14px;font-weight:400;">
            {{userDetails.location}} </span></p>
        <hr style="clear:left;">
        <h4 class="ginfo">General Information</h4>
        <p class="gpara"> <b>Name : </b> {{userDetails?.name}}<br>
          <b>Business Name : {{cropName(userDetails?.business_name, 15)}}</b> <br>
          <b>Mobile Number : {{userDetails?.business_mobile}}</b> <br>
          <b>Email : </b>{{userDetails?.email}}<br>
        </p>
      </div>
    </div>
    <div style="font-size: 14px;">
      <div>
        <a [routerLink]="['/livemap/live-tracking']" class="navbar-brand"><img style="height:30px"
            src="assets/images/logo.png"></a>
      </div>
    </div>
    <div style="font-size: 14px;margin-left: auto;">
      <a *ngIf="checkpath === 'live-tracking'" routerLink="/livemap/message" style="color: #fff;"><img
          style="height:18px" src="assets/images/document1.png">&nbsp;
        Message</a>
    </div>
    <div style="font-size: 14px;">
      <ul class="nav navbar-nav ml-auto rlogout">
        <li [class]="!showProfile ? 'nav-item dropdown': 'nav-item dropdown show' ">
          <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:;" id="download">
            <span *ngIf="userDetails.business_name">{{userDetails?.business_name}}</span>
            <span *ngIf="!userDetails.business_name">{{userDetails?.name}}</span> </a>
          <div
            [class]="!showProfile ?'dropdown-menu dropdown-menu-right user dropDownProfile': 'dropdown-menu dropdown-menu-right user show dropDownProfile'"
            aria-labelledby="download">
            <div class="row mt-4">
              <div class="col"></div>
              <div class="col-8 text-center">
                <img *ngIf="!userDetails.business_image" style="width: 25%;height: 19%!important;"
                  src="{{userDetails?.profile_image}}" id="dprofileimageone" name="imageone">
                <img *ngIf="userDetails.business_image" style="width: 25%;height: 19%!important;"
                  src="{{userDetails?.business_image}}" id="dbusinessimageone" name="imageone">
                <p class="mt-1" style="color: #fff;font-weight: 700;height: 5%;white-space: nowrap;">
                  {{userDetails?.name}}</p>
                <p class="" style="color: #fff;font-weight: 500;word-break: break-all;">{{userDetails?.email}}</p>
                <p style="color: #fff !important;;font-weight: 500;margin-left: -100%;cursor: pointer;padding: 0.25rem 1.5rem;"
                  (click)="openNav()"><i class="fa fa-user fa-fw"></i> Profile</p>
                <p style="color: #fff !important;;font-weight: 500;margin-left: -100%;cursor: pointer;padding: 0.25rem 1.5rem;"
                  (click)="logoutConfirm()"><i class="fa fa-sign-out fa-fw"></i> Logout</p>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <img *ngIf="!userDetails.business_image" class="headerImg" src="{{userDetails?.profile_image}}"
      id="dprofileimagetwo" name="imagetwo">
    <img *ngIf="userDetails.business_image" class="headerImg" src="{{userDetails?.business_image}}"
      id="dprofileimagetwo" name="imagetwo">
  </div>
</div>

<div id="confirmLogout" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"
          style="margin-top:-4px;font-size: 1.7em;margin-right: 4px;">&times;</button>
        <div style="text-align: center">
          <h5 class="modal-title" style="margin-top: 40px;font-size: 20px; font-weight: 700;">You are logging out</h5>
          <p style="font-size:13px;margin-bottom: 35px;">Are you sure you want to logout?</p>
        </div>
        <div style="text-align: center;margin-bottom: 45px;" class="logoutbtn">
          <button class="btn btn-primary login_button" data-dismiss="modal" (click)="logout()" type="submit"
            style="padding:7px 33px 7px 33px; margin-right:7px;">Log out</button>
          <!-- <button class="btn btn-outline login_button" data-dismiss="modal" type="submit" style="padding: 7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div id="alertCredModal" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" style="margin-top: 300px;">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="text-center" style="color:#383838">Your session has expired, you are being logged out.</h4>
        <button class="login_button" data-dismiss="modal" style="width: 130px;height: 50px;" (click)="logoutClose()"
          type="submit">OK</button>
      </div>
    </div>
  </div>
</div>