import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { OrdinalPipe, TimezonePipe } from './pipes';
import { RedirectInterceptorService } from './services';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent, MessageComponent, LiveTrackingComponent, LoginComponent } from './pages';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LiveTrackingComponent,
    HeaderComponent,
    MessageComponent,
    OrdinalPipe,
    TimezonePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatTabsModule,
    MatCardModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxFlagPickerModule,
    MatFormFieldModule,
    MatSelectModule,
    OverlayModule,
    NgxSkeletonLoaderModule
  ],
  providers: [MatDatepickerModule,
    { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
