import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';

enum MasAssignAPIs {
  UPDATE_RIDE_MILESTONE = 'update_ride_milestone'
}

@Injectable({
  providedIn: 'root'
})
export class MasAssignService {

  baseUrl = environment.socketApiURL;
  constructor(private httpClient: HttpClient,
    private errorService: ErrorService) { }

    update_assigned_trip_milestone (data: {access_token: string, mas_trip_id: number, milestone: number, date: string}) {
      return this.httpClient.post(`${this.baseUrl}${MasAssignAPIs.UPDATE_RIDE_MILESTONE}`, data)
    }
}
