import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ErrorService } from './error.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  baseUrl = environment.socketApiURL;
  constructor(private httpClient: HttpClient,
              private errorService: ErrorService) { }

  reschedule(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_reschedule_ride`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  geoLocation(): Observable<any> {
    return this.httpClient
      .post<any>(`https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDVxUnImtx75BtZS3yQJKhh_XgKFbWcKaE`, {})
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  complete(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_complete_ride`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  createTicket(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}add_ticket`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  cancel(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_cancel_ride`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  fetchTickets(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}fetch_tickets`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  editTicket(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}edit_ticket`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  getDriver(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}driver_location`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  startRide(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}start_ride`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  retryRide(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_retry_request`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  fetchCarTypes(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}fetch_car_types`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  arrivedRide(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_arrived_ride`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }
  
  getDriverRides(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}get_driver_ride_details`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  getRides(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}get_ride_info`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

}
