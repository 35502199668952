<app-header></app-header>
<div class="row" style="margin-top: -35px;min-height: 700px;max-width: 99%;" [ngClass]="{'whirl' : isLoading}">
    <div class="col-2 pt-5" style="background-color: #f8fafc;border-right: 1px solid #EBEFF2;">
        <ul>
            <li class="sideNavText"><a [routerLink]="['/livemap/live-tracking']" [routerLinkActive]="'is-active'">
                    <i class="fa fa-home pr-3" aria-hidden="true"></i>Home</a></li>
            <li class="sideNavText"> <a [routerLink]="['/livemap/message']" [routerLinkActive]="'is-active'"><i
                        class="fa fa-envelope pr-3" aria-hidden="true"></i>Message</a></li>
        </ul>
    </div>
    <div class="col pt-4">
        <div *ngIf="!activeMessage" class="row">
            <div class="col-5 px-4">
                <b class="messageHeader">Message</b>
            </div>
            <div class="col-7">
                <section class="row moveRight">
                    <button class="btn mr-2 createButton createButtonText" (click)="createnewmsg()">Create
                        New</button>
                    <button class="btn mr-2 createButton exportButtonText"
                        (click)="downloadFile(message, 'Messages')">Export
                        CSV</button>
                    <input type="search" id="search" name="search" [(ngModel)]="searchString" placeholder="Search"
                        class="form-control searchField searchText" autocomplete="off" (keyup.enter)="search()" />
                </section>
            </div>
        </div>
        <div *ngIf="!activeMessage" class="row">
            <div>
                <div class="tab-panel"
                    style="position: -webkit-sticky; position: sticky; top: 0px;background-color: white;z-index: 2;">
                    <div class="row" style="border-radius: 5px 5px 0px 0px">
                        <div class="col-auto  px-4">
                            <div class="cursor-pointer" [class.active-tab]="active_trip_tab == 1"
                                (click)="active_trip_tab = 1; fetchmesssage()">SMS Message</div>
                        </div>
                        <div class="col-auto">
                            <div class="cursor-pointer" [class.active-tab]="active_trip_tab == 0"
                                (click)="active_trip_tab = 0; fetchmesssage()">Email</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-4">
                        <table style="word-wrap: break-word;table-layout: fixed;"
                            class="table table-responsive trips dtrips">
                            <thead>
                                <tr class="">
                                    <th scope="col" class="desktop messageTableHeader">Message ID</th>
                                    <th scope="col" class="messageTableHeader">Date/Time Created</th>
                                    <th scope="col" class="desktop messageTableHeader">Title</th>
                                    <th scope="col" class="mobilecar messageTableHeader">Description</th>
                                    <th scope="col" class="desktop messageTableHeader">Sent To</th>
                                    <th scope="col" class="desktop messageTableHeader">Action</th>
                                    <th scope="col" class="desktop messageTableHeader">More</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let data of message" class="">
                                <ng-container *ngIf="message">
                                    <td class="desktop messageTableText"
                                        (click)="activeMessage = !activeMessage;activeMessageData=data">{{data.id}}</td>
                                    <td class="messageTableText"
                                        (click)="activeMessage = !activeMessage;activeMessageData=data">
                                        {{data.date_created|date:'MMM dd yyyy'}} <br> {{data.date_created|date:'hh:mm
                                        a'}} </td>
                                    <td class="desktop messageTableText"
                                        (click)="activeMessage = !activeMessage;activeMessageData=data">{{data.title}}
                                    </td>
                                    <td class="mobilecar messageTableText"
                                        (click)="activeMessage = !activeMessage;activeMessageData=data">
                                        {{data.description}}</td>
                                    <td class="desktop messageTableText"
                                        (click)="activeMessage = !activeMessage;activeMessageData=data">
                                        {{data.sender_types}}</td>
                                    <td class="desktop">
                                        <button type="submit" class="btn btn-primary btn-sm px-3"
                                            *ngIf="data.is_sent==0" [disabled]="disableresend"
                                            (click)="sendmessage(data)">Send</button>
                                        <button type="submit" class="btn btn-primary btn-sm" *ngIf="data.is_sent==1"
                                            [disabled]="disableresend" (click)="sendmessage(data)">Resend</button>
                                    </td>
                                    <td class="desktop">
                                        <ul class="nav navbar-nav ml-auto dstatus">
                                            <li class="nav-item dropdown tabledrop">
                                                <a data-toggle="dropdown" href="javascript(void)" id="download">
                                                    <i class="fa fa-ellipsis-h elipsisH"></i>
                                                </a>
                                                <div class="dropdown-menu user" aria-labelledby="download">
                                                    <a class="dropdown-item" data-toggle="modal"
                                                        [disabled]="data.is_sent==1"
                                                        [ngClass]="{'disable_button' : data.is_sent==1}"
                                                        (click)="EditDialog(data)">Edit</a>
                                                    <a class="dropdown-item" data-toggle="modal"
                                                        (click)="DeleteDialog(data)" [disabled]="data.is_sent===1"
                                                        [ngClass]="{'disable_button' : data.is_sent==1}">Delete</a>
                                                    <a class="dropdown-item" data-toggle="modal"
                                                        (click)="duplicatedialog(data.id)"
                                                        data-target="#document_dialog_duplicate">Duplicate</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="totallength == 0">
                                <tr class="noride">
                                    <td colspan="8" class="text-center"
                                        style="color:#000; padding:20px 87px 20px 87px!important;">
                                        No Data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="activeMessage" class="row">
            <div class="col-9 px-5">
                <span class="activeMessageHeading">Driver Onboarding <p class="activeMessageSubHeading mt-4 pl-3">
                        Message ID: {{activeMessageData.id}}</p></span>
            </div>
            <div class="col">
                <button class="btn backButton backButtonText" (click)="activeMessage = !activeMessage">Back</button>
            </div>
        </div>
        <hr />
        <div *ngIf="activeMessage" class="row">
            <div class="col-9 px-5">
                <p class="activeMessageInfoHeading">MESSAGE INFORMATION</p>
            </div>
            <div class="col">
                <button class="btn editDetailsButton editDetailsText" (click)="EditDialog(activeMessageData)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    Edit Details</button>
            </div>
        </div>
        <div *ngIf="activeMessage" class="row mt-2">
            <div class="col-2 px-5">
                <label class="activeMeesageLabel">Message ID </label>
            </div>
            <div class="col-5">
                <span class="activeMeesageText pl-3">{{activeMessageData.id}}
                </span>
            </div>
            <div class="col-2"><label class="activeMeesageLabel">Date/Time Created
                </label>
            </div>

            <div class="col">
                <span class="activeMeesageText pl-3"> {{activeMessageData.date_created|date:'MMM dd yyyy'}}</span>
            </div>

        </div>
        <div *ngIf="activeMessage" class="row">
            <div class="col-2 px-5">
                <label class="activeMeesageLabel">Title</label>
            </div>
            <div class="col">
                <span class="activeMeesageText pl-3">{{activeMessageData.title}}</span>
            </div>
        </div>
        <div *ngIf="activeMessage" class="row">
            <div class="col-2 px-5">
                <label class="activeMeesageLabel">Sent Via
                </label>
            </div>
            <div class="col">
                <span class="activeMeesageText pl-3">{{activeMessageData.send_medium}}</span>
            </div>
        </div>
        <div *ngIf="activeMessage" class="row">
            <div class="col-2 px-5">
                <label class="activeMeesageLabel">Description</label>
            </div>
            <div class="col">
                <span class="activeMeesageText pl-3"
                    style="word-break: break-word;">{{activeMessageData.description}}</span>
            </div>
            <div class="col-6"></div>
        </div>
        <div *ngIf="!activeMessage" class="row">
            <div class="col-lg-12 col-md-12 col-sm-12" style="padding-right: 22px;">
                <mat-paginator [length]="totallength" [showFirstLastButtons]="true" [hidePageSize]="true"
                    [pageSize]="query.limit" (page)="onPaginateChange($event)">
                </mat-paginator>
            </div>
        </div>
    </div>

</div>

<div id="createmessage" class="modal" [ngClass]="{'blurModal' : testPopup}" role="dialog" data-keyboard="false"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <!-- Modal content-->
        <div class="modal-content" style="height: 470px">
            <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                <p class="createMessageHeader">Create New Message</p>
            </div>
            <div class="modal-body" style="background-color: #f8fafc;">
                <form enctype="multipart/form-data" name="myForm2" #myForm2 method="post">
                    <div class="row mt-4 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Title</label>
                        </div>
                        <div class="col-9">
                            <input class="form-control createMessageFieldBox" [(ngModel)]="createmsg.title" name="title"
                                id="rider" required>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Send To</label>
                        </div>
                        <div class="col-9">
                            <select style="-webkit-appearance: auto !important;" name="type" id="type"
                                class="form-control createMessageFieldBox" [(ngModel)]="sendTo"
                                (change)=senderChecked($event)>
                                <option value="" disabled selected>Select Send To</option>
                                <option *ngFor="let sender of Senders" id="{{sender.value}}" [value]="sender.value">
                                    {{sender.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Select Type</label>
                        </div>
                        <div class="col-9">
                            <select style="-webkit-appearance: auto !important;" name="type" id="type"
                                [(ngModel)]="selectedsent_id" class="form-control createMessageFieldBox">
                                <option value="" disabled selected>Type</option>
                                <option [value]="0">Email</option>
                                <option [value]="1">SMS</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Message</label>
                        </div>
                        <div class="col-9">
                            <textarea rows="5" class="form-control px-2 createMessageFieldBox"
                                placeholder="Enter message here" style="background: #FFFFFF;
                                width: 400px;border: 1px solid #EBEFF2;box-sizing: border-box; height:auto;"
                                name="content" [(ngModel)]="createmsg.content" id="content" required></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                <button class="btn btn-outline login_button" data-dismiss="modal" type="submit"
                    style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                <button type="submit" class="btn btn-md" [ngClass]="{'invalid_class':myForm2.$invalid}"
                    [disabled]="!createmsg.content || !createmsg.title" (click)="phonepopup()"
                    style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Test</button>
                <button type="submit" class="btn btn-primary btn-md"
                    [disabled]="!createmsg.content || !createmsg.title " (click)="savemessage()"
                    style="padding:7px 33px 7px 33px;color: white;font-size: 12px;border-radius: 4px;;">Save</button>
            </div>
        </div>
    </div>
</div>


<!-- Edit message  -->

<div id="editdialog" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <!-- Modal content-->
        <div class="modal-content" style="height: 470px">
            <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                <p class="createMessageHeader">Edit Message</p>
            </div>
            <div class="modal-body" style="background-color: #f8fafc;">
                <form enctype="multipart/form-data" name="myForm1" #myForm1 method="post">
                    <div class="row mt-4 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Title</label>
                        </div>
                        <div class="col-9">
                            <input class="form-control createMessageFieldBox" [(ngModel)]="editdata.title" name="title"
                                id="rider" required>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Send To</label>
                        </div>
                        <div class="col-9">
                            <select name="type" id="type" class="form-control createMessageFieldBox"
                                (change)=setSenderDetail($event)>
                                <option *ngFor="let sender of Senders" [id]="sender.value" [selected]="sender.checked"
                                    value="{{sender.value}}">
                                    {{sender.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Type</label>
                        </div>
                        <div class="col-9">
                            <select name="send_via" id="send_via" [(ngModel)]="editdata.send_via"
                                class="form-control createMessageFieldBox">
                                <option [value]="0">Email</option>
                                <option [value]="1">SMS</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3">
                        <div class="col-3">
                            <label class="createMessageFieldText">Message</label>
                        </div>
                        <div class="col-9">
                            <textarea rows="5" class="form-control px-2 createMessageFieldBox"
                                placeholder="Enter message here" style="background: #FFFFFF;
                                width: 400px;border: 1px solid #EBEFF2;box-sizing: border-box; height:auto;"
                                name="content" [(ngModel)]="editdata.description" id="content" required></textarea>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                <button class="btn btn-outline login_button" data-dismiss="modal" type="submit"
                    style="padding:7px 33px 7px 33px; margin-right:7px;background: #8692A6;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>

                <button type="submit" class="btn btn-primary btn-md"
                    [disabled]="editdata.description == cachedEditData.description &&
                            editdata.title == cachedEditData.title &&
                            editdata.send_via == cachedEditData.send_via && senderDetail.value == cachedEditData.sent_to" (click)="edittedmsg()"
                    style="padding:7px 33px 7px 33px;color: white;font-size: 12px;border-radius: 4px;;">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Snd by phone -->
<div id="phonetest" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content" style="width: 100%;height: 100%;">
            <div class="modal-header" style="height: 73px;background: #FFFFFF;border-radius: 4px 4px 0px 0px;">
                <p class="createMessageHeader" *ngIf="selectedsent_id=='1'">Test SMS</p>
                <p class="createMessageHeader" *ngIf="selectedsent_id=='0'">Test Email</p>
            </div>
            <div class="modal-body" style="background-color: #f8fafc;">
                <form enctype="multipart/form-data" name="testform" method="post">
                    <div class="mx-5 my-4">
                        <p style="color: #334D6E;font-style: normal;
                        font-weight: 510;font-size: 13px;line-height: 19px;">Send to individual message</p>
                        <input *ngIf="selectedsent_id=='0'" class="form-control" [(ngModel)]="testform1.email"
                            name="email" (input)="validateEmail($event);" required type="text">
                        <div class="row" style="margin-left: -30px;">
                            <div class="col-2">
                                <ngx-flag-picker class="form-control pt-1 ml-3"
                                    style="width:fit-content; border-right-style: hidden;height:70%;"
                                    *ngIf="selectedsent_id=='1'" [selectedCountryCode]="selectedCountryCode"
                                    [countryCodes]="countryCodes" [showLabels]="false"
                                    (changedCountryCode)="changeSelectedCountryCode($event)">
                                </ngx-flag-picker>
                            </div>
                            <div class="col">
                                <input *ngIf="selectedsent_id=='1'" class="form-control" [(ngModel)]="testform1.mobno"
                                    maxlength="10" (input)="validateMobile($event);" name="mobno" required type="text"
                                    placeholder="Enter mobile here"
                                    style="width:80%;height:70%;border-left-style: hidden;border-bottom-left-radius: 0px !important;border-top-left-radius: 0px !important;"
                                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 189">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="height: 72px;background: #FFFFFF;border-radius: 0px 0px 5px 5px;">
                <button class="btn btn-outline login_button" data-dismiss="modal" type="submit"
                    (click)="testPopup = false"
                    style="padding:7px 33px 7px 33px; margin-right:7px;background: #C2CFE0;;color: white;font-size: 12px;border-radius: 4px;">Cancel</button>
                <button type="submit" class="btn btn-primary btn-md" [disabled]="testbutton"
                    style="padding:7px 33px 7px 33px;margin-right:50px;background: #2C71C3;color: white;font-size: 12px;border-radius: 4px;;"
                    (click)="test(true,selectedsent_id)">Send</button>
            </div>
        </div>
    </div>
</div>
<div id="deletemessage" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal"
                    style="margin-top:-4px;font-size: 1.7em;margin-right: 4px;">&times;</button>
                <div style="text-align: center;padding-top: 25px;">
                    <p style="font-size:16px;margin-bottom: 35px;" *ngIf="send_via=='1'">Are you sure you want to delete
                        this message?</p>
                    <p style="font-size:16px;margin-bottom: 35px;" *ngIf="send_via=='0'">Are you sure you want to delete
                        this email?</p>
                </div>
                <div style="text-align: center;" class="logoutbtn">
                    <button class="btn btn-primary login_button" data-dismiss="modal" (click)="DeleteMessage()"
                        type="submit" style="padding:7px 33px 7px 33px; margin-right:7px;">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="duplicatedialog" class="modal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal"
                    style="margin-top:-4px;font-size: 1.7em;margin-right: 4px;">&times;</button>
                <div style="text-align: center">
                    <p style="font-size:16px;margin-bottom: 35px;">Are you sure you want to create duplicate
                        message?</p>
                </div>
                <div style="text-align: center;" class="logoutbtn">
                    <button class="btn btn-primary login_button" data-dismiss="modal" (click)="Duplicate()"
                        type="submit" style="padding:7px 33px 7px 33px; margin-right:7px;">Yes</button>
                    <button class="btn btn-outline login_button" data-dismiss="modal" type="submit"
                        style="padding: 7px 33px 7px 33px; border:1px solid #aaa; text-shadow: none;">No</button>
                </div>
            </div>
        </div>
    </div>
</div>