import * as io from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;
  constructor() {
  }

  setupSocketConnection(): void {
    this.socket = io(environment.socketURL);
  }

  emit(eventName: any, data: any): void {
    this.socket.emit(eventName, data);
  }

  removeAllListeners(): void {
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }

  on(eventName): Observable<any> {
    const subject = new Subject();
    this.socket.on(eventName, (data: any) => {
      subject.next([data]);
    });
    return subject.asObservable();
  }

  off(eventName: string): void {
    this.socket.removeAllListeners(eventName);
  }

  once(eventName: string): Observable<any> {
    const subject = new Subject();
    this.socket.once(eventName, (data: any) => {
      subject.next([data]);
    });
    return subject.asObservable();
  }
}
