import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorService } from './error.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  baseUrl = environment.socketApiURL;
  constructor(private httpClient: HttpClient,
              private errorService: ErrorService) { }


  search(query: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_fetch_messages`, query)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  searchNew(query: any): Observable<any> {
    const params = new URLSearchParams();
    for (const key in query) {
      params.set(key, query[key]);
    }
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') };
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_new_message`, params.toString(), options)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  update(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_edit_message`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  duplicateMsg(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_duplicate_message`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  delete(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_delete_message`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  test(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_test_message`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }

  send(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}livemap_send_message`, data)
      .pipe(
        catchError(this.errorService.handleError),
        map((res) => res)
      );
  }
}
