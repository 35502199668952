import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value && value.toString()) {
      case '330':
        return 'IST'
      case 'Eastern Standard Time':
        return 'EST'
      case '-240':
          return 'EDT'
      case '-360':
          return 'CST'
      case '-300':
          return 'EST'
      case '-420':
          return 'PDT'
      case '480':
          return 'PHT'
      default:
          return 'N/A'
    }
  }

}
