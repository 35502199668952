import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { LoginService, ToasterService } from '../../services';
declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  word: RegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
  loginUser = {
    email: '',
    password: ''
  };
  constructor(private loginService: LoginService,
    private router: Router,
    private toasterService: ToasterService) {
  }

  ngOnInit(): void {
    $('#mobile').focus();
    $('#extPopTwo').val('+1');
    $('#extPopThree').val('+1');
    $('body').addClass('login-page');
  }

  login(): void {
    if (!this.loginUser.email) {
      alert('Please enter Email.');
    } else if (!this.loginUser.password) {
      alert('Please enter Password.');
    } else if (this.loginUser.email && this.loginUser.password) {

      const data = {
        email: this.loginUser.email,
        password: this.loginUser.password
      };
      this.loginService.login(data)
        .subscribe((response: any) => {
          if (typeof (response) == 'string') {
            response = JSON.parse(response);
          }

          if (response.flag == 405) {
            this.toasterService.open('Email or Password is incorrect', 'red-snackbar');
          } else if (response.flag == 103) {
            this.toasterService.open(response.error, 'red-snackbar');
          } else if (response.access_token) {
            const params = {
              email: this.loginUser.email,
              name: response.live_user_name,
              password: this.loginUser.password,
              web_access_token: response.access_token,
              profile_image: response.profile_image,
              live_user_type: response.live_user_type,
              business_name: response.business_name,
              business_image: response.business_image,
              business_mobile: response.business_mobile
            };

            localStorage.setItem('LivemapModel', JSON.stringify(params));
            localStorage.setItem('web_access_token', params.web_access_token);
            localStorage.setItem('access_token', params.web_access_token);
            this.router.navigate(['livemap/live-tracking']);
          }
        });
    } else {
      alert('Invalid Email or Password.');
    }
  }

  pressLoginEnter(e: any): void {
    if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
      if (this.loginUser.email.length !== 0) {
        this.login();
      }
    }
  }
}
