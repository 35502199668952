
export const MileStone_Map = {
    1: 'Start Pickup',
    2: 'Arrived At Pickup',
    3: 'Start Trip',
    4: 'Arrive At Dropoff',
    // 5: 'Completed',
    5: 'End Trip',
    6: 'No Show',
};

export const Milestone_Display_Label_Map = {
    0: 'Confirmed',
    1: 'Picking Up',
    2: 'Arrived',
    3: 'En Route',
    4: 'At Dropoff',
    5: 'Completed',
};

export const MileStone_Class = {
    1: 'bg-theme btn-primary',
    2: 'bg-green btn-success',
    3: 'bg-orange btn-warning',
    4: 'bg-green btn-success',
    5: 'bg-orange btn-warning',
};

export const Milestone_Update_Confirmation = {
    1: 'You are starting to pickup the rider of this trip, please confirm',
    2: 'You arrived at the pickup location, please confirm',
    3: 'You are starting this trip, please confirm',
    4: 'You arrived at the drop off location, please confirm',
    5: 'You are completing this trip, please confirm',
    6: 'No one shows at pickup location, please confirm',
};
