import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CountryService, GlobalEventService, LoginService } from '../../services';
declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  checkpath: string = '';
  profile = {};
  tab: string = 'United States';
  tab1: string = '';
  countries = [];
  showCountries: boolean = false;
  showClients: boolean = false;
  showProfile: boolean = false;
  user_image: string = '';
  usernewimage: string = '';
  pop = {};
  d: Date = new Date();
  access_token: string = localStorage.getItem('access_token');
  livemapModel = JSON.parse(localStorage.getItem('LivemapModel'));
  userDetails = {
    first_name: '',
    name: '',
    email: '',
    profile_image: '',
    driver_name: '',
    location: '',
    business_name: '',
    business_image: '',
    business_mobile: ''
  };

  clients = [{ name: 'Business Name' }]
  selectedClient = { name: 'Business Name' }
  
  constructor(private countryService: CountryService,
    private loginService: LoginService,
    private router: Router,
    private globalEventService: GlobalEventService) {
    this.globalEventService.changeCountrySubject.subscribe(receiveddata => {
      this.tab = receiveddata.country_name;
    });
  }

  changeClient(client): void {
    this.selectedClient = client;
  }

  cropName(name: string, charSize: number): string {
    if (name && name.length > charSize) {
      return name.substring(0, charSize) + '...'
    } else {
      return name
    }
  }

  initCss(): void {
    $(document).bind(
      'touchmove',
      function (e) {
        if ($('.notfMenuMobile').hasClass('open')) {
          $('.notfMenuMobile').toggleClass('open');
        }
      }
    );
    if ($(window).width() < 800) {
      $('#subMenu ul').on('click', function () {
        $('.subMenu ul.nav div').css('transform', 'rotateX(-88deg)');
      });
      $('li a').on('click', function () {
        $('.subMenu ul.nav div').css('transform', 'rotateX(0deg)');
      });
      window.addEventListener('click', function (e: any) {
        if (document.getElementById('navMenu').contains(e.target)) {
        } else {
          $('.subMenu ul.nav div').css('transform', 'rotateX(-88deg)');
        }
      });
    }
    $('body').removeClass('login-page');
  }

  loadCss(): void {
    $('#selectpdpimage').change(function (e) {
      if (this.files && this.files[0]) {
        const reader = new FileReader();
        reader.onload = function (e) {
          $('#editdpimage').attr('src', e.target.result);
        };
        reader.readAsDataURL(this.files[0]);
      }
    });
  }

  init(): void {
    this.initCss();
    this.loadCss();

    if (this.access_token === undefined) {
      this.showCredentialsAlert();
    }
    $('img').on('dragstart', function (event) {
      event.preventDefault();
    });

    $('input').attr('autocomplete', 'off');

    this.d.setMinutes(0);
    this.d.setHours(0);
    this.d.setSeconds(0);

    $('.menuItems').click(function () {
      $('.menuItems').removeClass('active');
      $(this).addClass('active');
    });

    this.initMenu();

    if (this.livemapModel) {
      this.userDetails = this.livemapModel;
    } else {
      localStorage.removeItem('access_token');
    }

    if (localStorage.getItem('web_access_token')) {
      this.countrieslist();
    }
  }

  openNav(): void {
    $('#mySidenav').show();
    const w = window.innerWidth;
    if (w > 500) {
      $('#mySidenav').css('width', '500px');
    } else {
      $('#mySidenav').css('width', '100%');
    }
  }

  closeNav(): void {
    $('#mySidenav').css('width', '0px');
  }

  logoutConfirm(): void {
    $('#confirmLogout').modal('show');
  }

  showCredentialsAlert(): void {
    $('#alertCredModal').modal('show');
  }

  initMenu(): void {
    $('.menuItems').on('click', function () {
      $('.menuItems').removeClass('active');
      $(this).addClass('active');
    });
  }

  // Countries list //
  countrieslist(): void {
    const data = {
      access_token: localStorage.getItem('access_token')
    };

    this.countryService.list(data)
      .subscribe(data => {
        if (typeof (data) == 'string') {
          data = JSON.parse(data);
        }
        if (data.flag == 101) {
          localStorage.clear();
          this.tab1 = '';
        } else {
          this.countries = data.countries;
          this.countries.forEach(function (column) {
            const dataArray1 = [];
            if (column.country_id == 1) {
              dataArray1.push(column);
            }
          });
        }
      });
  }

  logoutClose(): void {
    this.logout();
  }

  logout(): void {
    const data = {
      web_access_token: this.livemapModel.web_access_token,
    };
    this.loginService.logout(data)
      .subscribe((response: any) => {
        if (typeof (response) == 'string') {
          response = JSON.parse(response);
        }
        if (response.flag == 114) {
          $('.modal').modal('hide');
          localStorage.clear();
          this.router.navigate(['login']);
          this.tab1 = '';
        } else if (response.flag == 101) {
          $('.modal').modal('hide');
          localStorage.clear();
          this.router.navigate(['login']);
          this.tab1 = '';
        }
      });
  }

  closeDropDown(): void {
    $('.sub').css('transform', 'rotateX(-88deg)');
  }

  ngOnInit(): void {
    this.init();
    this.checkpath = this.router.url.split('/').pop();
  }

  countryChange(val: any, values: any): void {
    if (values) {
      this.globalEventService.changeCountry(values);
    }
  }

}
